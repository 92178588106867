import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../store';
import LeadsList from './LeadsList';
import LeadContacts from './LeadsContacts';
import InteractionsList from '../interactions/InteractionsList';

import { ReactComponent as ListIcon } from '../../icons/client.svg';
import { ReactComponent as ContentIcon } from '../../icons/content.svg';
import { ReactComponent as DetailsIcon } from '../../icons/person.svg';

const LeadsPage = () => {
  const { leadId } = useParams();
  const {
    visibleSection,
    setVisibleSection,
    fetchLeads,
    setSelectedLead,
    leads,
    selectedLead
  } = useStore(state => ({
    visibleSection: state.visibleSection,
    setVisibleSection: state.setVisibleSection,
    fetchLeads: state.fetchLeads,
    setSelectedLead: state.setSelectedLead,
    leads: state.leads,
    selectedLead: state.selectedLead
  }));

  useEffect(() => {
    if (!leads.length) { // Enhance this check to handle non-empty but outdated cases
      fetchLeads();
    }
  }, [fetchLeads, leads.length]);

  useEffect(() => {
    if (leadId && leads.length) { // Ensure leads array is checked for presence
      const lead = leads.find(l => l._id === leadId);
      setSelectedLead(lead);
    }
  }, [leadId, leads, setSelectedLead]);

  // Switch to 'content' view upon selection to give immediate context
  useEffect(() => {
    if (selectedLead) {
      setTimeout(function(){
        setVisibleSection('content');
      })
      
    }
  }, [selectedLead, setVisibleSection]);

  return (
    <>
      <div className={`mobile-navigation ${visibleSection}`}>
        <button className='btn' onClick={() => setVisibleSection('list')}><ListIcon /> List</button>
        <button className='btn' onClick={() => setVisibleSection('content')} disabled={!selectedLead}><ContentIcon /> Content</button>
        <button className='btn' onClick={() => setVisibleSection('details')} disabled={!selectedLead}><DetailsIcon /> Details</button>
      </div>
      {selectedLead && (
          <div className="selected-item-header">
            Selected Lead: <strong>{selectedLead.name}</strong>
          </div>
        )}
      <div className={`component component-page componentLeadsPage ${visibleSection}`}>
        <div className={`section section-list`}>
          <LeadsList />
        </div>
        {selectedLead && (
          <div className={`section section-content`}>
            <InteractionsList entity={selectedLead} entityId={selectedLead._id} entityName={selectedLead.name} />
          </div>
        )}
        {selectedLead && (
          <div className={`section section-details`}>
            <LeadContacts />
          </div>
        )}
      </div>
    </>
  );
}

export default LeadsPage;
