import React, { useState, useEffect } from 'react';
import useStore from '../../store'; // Path to your Zustand store
import { Link } from 'react-router-dom';
import { ReactComponent as PasswordIcon } from '../../icons/password.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const ManagerContacts = () => {
  const selectedManager = useStore(state => state.selectedManager);
  const updateManager = useStore(state => state.updateManager);
  const removeManager = useStore(state => state.removeManager);
  const setPassword = useStore(state => state.setPassword);

  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: ''
  });

  useEffect(() => {
    if (selectedManager) {
      setEditFormData({
        firstname: selectedManager.firstname,
        lastname: selectedManager.lastname,
        email: selectedManager.email,
        phone: selectedManager.phone,
        role: selectedManager.role
      });
    }
  }, [selectedManager]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateManager({ ...selectedManager, ...editFormData });
      setIsEditing(false);
    } catch (error) {
      console.error('Update failed:', error);
    }
  };

  const handleSetPassword = async () => {
    const newPassword = prompt('Enter new password:');
    if (newPassword) {
      await setPassword(selectedManager._id, newPassword);
    } else {
      alert('Password update cancelled');
    }
  };

  const handleRemove = async () => {
    if (window.confirm("Are you sure you want to delete this manager?")) {
      try {
        await removeManager(selectedManager._id);
      } catch (error) {
        console.error('Deletion failed:', error);
      }
    }
  };

  if (!selectedManager) {
    return '';
  }

  return (
    <div className="component component-details componentManagerContacts card">
      <div className='block block-hor block-details-header'>
        <h2>Contact details</h2>
        <div className='block block-hor block-details-buttons'>
          <p className='details-button details-button-edit'>
            <a className='btn btn-round btn-secondary' onClick={() => setIsEditing(true)}><EditIcon /></a>
          </p>
          <p className='details-button details-button-password'>
            <a className='btn btn-round btn-secondary' onClick={handleSetPassword}><PasswordIcon /></a>
          </p>
          {selectedManager.role === 'salesManager' && (
            <p className='details-button details-button-remove'>
              <a className='btn btn-round btn-secondary' onClick={handleRemove}><DeleteIcon /></a>
            </p>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className="card card-form">
          <form>
            <div className="form-group w100">
              <label>First Name:</label>
              <input type="text" name="firstname" value={editFormData.firstname} onChange={handleEditChange} required />
            </div>
            <div className="form-group w100">
              <label>Last Name:</label>
              <input type="text" name="lastname" value={editFormData.lastname} onChange={handleEditChange} required />
            </div>
            <div className="form-group w100">
              <label>Email:</label>
              <input type="email" name="email" value={editFormData.email} onChange={handleEditChange} required />
            </div>
            <div className="form-group w100">
              <label>Phone:</label>
              <input type="text" name="phone" value={editFormData.phone} onChange={handleEditChange} required />
            </div>
            <div className="block block-hor card-form-buttons">
              <button type="button" onClick={() => setIsEditing(false)} className="btn btn-secondary"><CancelIcon /> Cancel</button>
              <button type="button" onClick={handleSave} className="btn btn-acc"><SaveIcon /> Save</button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <p className='label'>First name</p>
          <h2 className='value'>{selectedManager.firstname}</h2>
          <p className='label'>Last name</p>
          <h2 className='value'>{selectedManager.lastname}</h2>
          <p className='label'>Email</p>
          <h2 className='value'><a href={`mailto:${selectedManager.email}`}>{selectedManager.email}</a></h2>
          <p className='label'>Phone</p>
          <h2 className='value'><a href={`tel:${selectedManager.phone}`}>{selectedManager.phone}</a></h2>
          <p className='label'>Role</p>
          <h2 className='value'>{selectedManager.role}</h2>

          <Link to={`/managers/${selectedManager._id}/emailsettings`} >
            Email Settings
          </Link>
        </div>
      )}
    </div>
  );
};

export default ManagerContacts;
