import React, { useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import EmailSettings from './EmailSettings'; // Import the EmailSettings component
import SendEmailForm from './SendEmailForm'; // Import the SendEmailForm component



const EmailsPage = () => {
  const { managerId } = useParams();
  const [activeSection, setActiveSection] = useState('settings'); // Default to settings

  const renderSection = () => {
    switch (activeSection) {
      case 'settings':
        return <EmailSettings />;
      case 'send':
        return <SendEmailForm customerEmail="" managerId={managerId} />; // Placeholder email
      default:
        return <EmailSettings />;
    }
  };

  return (
    <div className='component componentEmailsPage'>
      <div className='sub-navigation'>
        <button className={`btn ${activeSection === 'settings' ? 'active' : ''}`} onClick={() => setActiveSection('settings')}>
           Email Settings
        </button>
        <button className={`btn ${activeSection === 'send' ? 'active' : ''}`} onClick={() => setActiveSection('send')}>
           Send Email
        </button>
      </div>
      <div className='section-content'>
        {renderSection()}
      </div>
    </div>
  );
};

export default EmailsPage;
