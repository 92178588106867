import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import Select from 'react-select';

import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const ProjectDetails = () => {
  const {
    selectedProject,
    updateProject,
    removeProject,
    clients,
    fetchClients
  } = useStore(state => ({
    selectedProject: state.selectedProject,
    updateProject: state.updateProject,
    removeProject: state.removeProject,
    clients: state.clients,
    fetchClients: state.fetchClients
  }));

  console.log('selectedProject: ', selectedProject);

  useEffect(() => {
    if (clients.length === 0) {
      fetchClients();
    }
  }, [clients.length, fetchClients]);

  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState({
    projectName: selectedProject?.projectName || '',
    projectStatus: selectedProject?.projectStatus || '',
    startDate: selectedProject?.startDate?.substring(0, 10) || '',
    endDate: selectedProject?.endDate?.substring(0, 10) || '',
    details: selectedProject?.details || '',
    customer: selectedProject?.customer || '',
    basicPrice: selectedProject?.basicPrice || '',  // Add basicPrice to the form data
    monthPrice: selectedProject?.monthPrice || ''   // Add monthPrice to the form data
  });

  useEffect(() => {
    if (selectedProject) {
      setEditFormData({
        projectName: selectedProject.projectName,
        projectStatus: selectedProject.projectStatus,
        details: selectedProject.details,
        customer: selectedProject.customer ? {
          value: selectedProject.customer._id,
          label: selectedProject.customer.name
        } : null,
        basicPrice: selectedProject.basicPrice || '',  // Populate basicPrice
        monthPrice: selectedProject.monthPrice || ''   // Populate monthPrice
      });
    }
  }, [selectedProject]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCustomerChange = (selectedOption) => {
    setEditFormData(prevState => ({
      ...prevState,
      customer: selectedOption // Set the entire object directly
    }));
  };

  const customerOptions = clients.map(client => ({
    value: client._id,
    label: client.name
  }));

  const handleSave = async (e) => {
    e.preventDefault();

    // Prepare the data for submission, including prices
    const submissionData = {
      projectName: editFormData.projectName,
      projectStatus: editFormData.projectStatus,
      details: editFormData.details,
      customer: editFormData.customer ? editFormData.customer.value : null, // Ensure you're sending only the customer ID
      basicPrice: editFormData.basicPrice,  // Include basicPrice
      monthPrice: editFormData.monthPrice   // Include monthPrice
    };

    try {
      await updateProject(selectedProject._id, submissionData);
      setIsEditing(false);
    } catch (error) {
      console.error('Update failed:', error);
    }
  };

  const handleRemove = async () => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await removeProject(selectedProject._id);
      } catch (error) {
        console.error('Deletion failed:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // British format which is DD/MM/YYYY
  };

  return (
    <div className="component componentClientContacts card">
      <div className='block block-hor block-details-header'>
        <h2>Project Details</h2>
        <div className='block block-hor block-details-buttons'>
          <p className='details-button details-button-edit'>
            <a className='btn btn-round btn-secondary' onClick={() => setIsEditing(true)}><EditIcon /></a>
          </p>
          <p className='details-button details-button-remove'>
            <a className='btn btn-round btn-secondary' onClick={handleRemove}><DeleteIcon /></a>
          </p>
        </div>
      </div>
      {isEditing ? (
        <div className="component componentClientNew card card-form">
          <form onSubmit={handleSave}>
            <div className="form-group w100">
              <label>Project name:</label>
              <input type="text" name="projectName" value={editFormData.projectName} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <label>Project status:</label>
              <select name="projectStatus" value={editFormData.projectStatus} onChange={handleEditChange}>
                <option value="LEAD">LEAD</option>
                <option value="CONTRACT">CONTRACT</option>
                <option value="CHANCE">CHANCE</option> {/* Change the value to "CHANCE" */}
              </select>
            </div>
            <div className="form-group w100">
              <label>Customer:</label>
              <Select
                value={editFormData.customer}
                onChange={handleCustomerChange}
                options={customerOptions}
                className="basic-single"
                classNamePrefix="select"
                name="customer"
                isClearable
              />
            </div>
            <div className="form-group w100">
              <label>Basic Price:</label>  {/* Add input for basicPrice */}
              <input type="number" name="basicPrice" value={editFormData.basicPrice} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <label>Monthly Price:</label>  {/* Add input for monthPrice */}
              <input type="number" name="monthPrice" value={editFormData.monthPrice} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <label>Details:</label>
              <textarea name="details" value={editFormData.details} onChange={handleEditChange}></textarea>
            </div>
            <div className="block block-hor card-form-buttons">
              <button className="btn btn-secondary" type="button" onClick={() => setIsEditing(false)}><CancelIcon />Cancel</button>
              <button className="btn btn-acc" type="submit"><SaveIcon />Save</button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <p className='label'>Name:</p>
          <h2 className='value'>{selectedProject.projectName}</h2>
          <p className='label'>Status:</p>
          <h2 className='value'>{selectedProject.projectStatus}</h2>
          <p className='label'>Customer:</p>
          <h2 className='value'>{selectedProject.customer.name}<br />
            <a href={`mailto:${selectedProject.customer.email}`}>{selectedProject.customer.email}</a>
            <a href={`tel:${selectedProject.customer.phone}`}>{selectedProject.customer.phone}</a>
          </h2>
          <p className='label'>First payment:</p>  {/* Display basicPrice */}
          <h2 className='value'>{selectedProject.basicPrice}</h2>
          <p className='label'>Monthly payment:</p>  {/* Display monthPrice */}
          <h2 className='value'>{selectedProject.monthPrice}</h2>
          <p className='label'>Details:</p>
          <h2 className='value'>{selectedProject.details}</h2>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
