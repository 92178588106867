import '../../../src/air-datepicker.min.css';
import 'air-datepicker/locale/en.js';
import AirDatepicker from 'air-datepicker';
import moment from 'moment';

import React, { useState, useEffect, useRef } from 'react';
import useStore from '../../store';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as MeetingIcon } from '../../icons/meeting.svg';
import { ReactComponent as NoteIcon } from '../../icons/note.svg';
import { ReactComponent as OtherIcon } from '../../icons/other.svg';

function formatDate(date) {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
}

function formatTime(date) {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
}

const InteractionForm = () => {
  const { addInteraction, managers, role, userId, selectedProject } = useStore(state => ({
    addInteraction: state.addInteraction,
    managers: state.managers,
    role: state.role,
    userId: state.userId,
    selectedProject: state.selectedProject
  }));

  const initialDate = moment().format('DD/MM/YYYY');
  const initialTime = moment().format('HH:mm');

  const [interaction, setInteraction] = useState({
    type: '',
    date: initialDate,
    time: initialTime,
    details: '',
    status: '',
    manager: userId
  });

  const setIsNewActivity = useStore(state => state.setIsNewActivity);

  const datePickerRef = useRef(null);
  const timePickerRef = useRef(null);

  const englishLocale = {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'yyyy-MM-dd',
    timeFormat: 'HH:mm',
    firstDay: 0
  };

  useEffect(() => {
    const dp = new AirDatepicker(datePickerRef.current, {
      locale: englishLocale,
      dateFormat: 'yyyy-MM-dd',
      isInline: true, // Inline display
      onSelect({ date, formattedDate }) {
        const formattedDateOnly = formatDate(date);
        setInteraction(prev => ({ ...prev, date: formattedDateOnly }));
      }
    });

    const tp = new AirDatepicker(timePickerRef.current, {
      locale: englishLocale,
      timepicker: true,
      onlyTimepicker: true,
      dateFormat: 'HH:mm',
      isInline: true, // Inline display
      onSelect({ date }) {
        const formattedTimeOnly = formatTime(date);
        setInteraction(prev => ({ ...prev, time: formattedTimeOnly }));
      }
    });

    return () => {
      dp.destroy();
      tp.destroy();
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInteraction(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!interaction.type) {
      alert('Please select a type for the interaction.');
      return;
    }
    if (role === 'boss' && !interaction.manager) {
      alert('Please select a manager.');
      return;
    }
    if (!interaction.date || !interaction.time) {
      alert('Please select both date and time for the interaction.');
      return;
    }

    if (!selectedProject) {
      alert('No project selected. Cannot add interaction.');
      return;
    }

    // Combine date and time into a single ISO string
    const combinedDateTime = moment(`${interaction.date} ${interaction.time}`, 'DD/MM/YYYY HH:mm').toISOString();

    const interactionData = {
      ...interaction,
      date: combinedDateTime,
      project: selectedProject._id,
      manager: interaction.manager || userId,
      status: new Date(combinedDateTime) >= new Date() ? 'planned' : 'completed'
    };

    try {
      await addInteraction(interactionData);
      alert('Interaction added successfully!');
      setInteraction({
        type: '',
        date: initialDate,
        time: initialTime,
        details: '',
        manager: role === 'boss' ? '' : userId
      });
      setIsNewActivity(false);
    } catch (error) {
      console.error('Error adding interaction:', error);
      alert(`Failed to add interaction: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  return (
    <div className='component componentInteractionForm card card-form'>
      <form onSubmit={handleSubmit}>
        <div className="form-group w100">
          <label>Type:</label>
          <div className="radio-group radio-group-type">
            <label className={`radio-label radio-label-email ${interaction.type === 'email' ? 'active' : ''}`}>
              <input type="radio" name="type" value="email" checked={interaction.type === 'email'} onChange={handleInputChange} />
              <EmailIcon /> Email
            </label>
            <label className={`radio-label radio-label-phone ${interaction.type === 'phone' ? 'active' : ''}`}>
              <input type="radio" name="type" value="phone" checked={interaction.type === 'phone'} onChange={handleInputChange} />
              <PhoneIcon /> Phone Call
            </label>
            <label className={`radio-label radio-label-meeting ${interaction.type === 'meeting' ? 'active' : ''}`}>
              <input type="radio" name="type" value="meeting" checked={interaction.type === 'meeting'} onChange={handleInputChange} />
              <MeetingIcon /> Meeting
            </label>
            <label className={`radio-label radio-label-note ${interaction.type === 'note' ? 'active' : ''}`}>
              <input type="radio" name="type" value="note" checked={interaction.type === 'note'} onChange={handleInputChange} />
              <NoteIcon /> Note
            </label>
            <label className={`radio-label radio-label-other ${interaction.type === 'other' ? 'active' : ''}`}>
              <input type="radio" name="type" value="other" checked={interaction.type === 'other'} onChange={handleInputChange} />
              <OtherIcon /> Other
            </label>
          </div>
        </div>

        <div className="form-group w50">
          <label>Date:</label>
          <div ref={datePickerRef} className="inline-datepicker"></div>  {/* Inline datepicker */}
        </div>
        <div className="form-group w50">
          <label>Time:</label>
          <div ref={timePickerRef} className="inline-timepicker"></div>  {/* Inline timepicker */}
        </div>
        <div className="form-group w100">
          <label>Details:</label>
          <textarea name="details" value={interaction.details} onChange={handleInputChange}></textarea>
        </div>
        <div className="block block-hor card-form-buttons">
          <button type="button" className="btn btn-secondary" onClick={() => setIsNewActivity(false)}><CancelIcon />Cancel</button>
          <button type="submit" className="btn btn-acc"><AddIcon />Add Activity</button>
        </div>
      </form>
    </div>
  );
};

export default InteractionForm;
