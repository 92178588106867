import React, { useState, useEffect, useRef } from 'react';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/locale/en.js';
import 'air-datepicker/air-datepicker.css';
import { useNavigate, useLocation } from 'react-router-dom';
import useStore from '../../store';
import ToDoInteraction from './ToDoInteraction';

const ToDoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const datePickerRef = useRef(null);
  const userId = localStorage.getItem('userId');

  const { todoInteractions, fetchTodoInteractions, overdueInteractions, fetchOverdueInteractions } = useStore();

  // Extract date from URL or use the current date
  const queryParams = new URLSearchParams(location.search);
  const urlDate = queryParams.get('date');
  const initialDate = urlDate ? new Date(urlDate) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [activeSection, setActiveSection] = useState('overdue'); // 'overdue' or 'date'

  useEffect(() => {
    const dp = new AirDatepicker(datePickerRef.current, {
      locale: {
        days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        daysShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        daysMin: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'yyyy-MM-dd',
        timeFormat: 'HH:mm',
        firstDay: 0  // Set Monday as the first day of the week
      },
      inline: true,
      autoClose: false,
      isMobile: false,
      minDate: new Date(),
      onSelect({ date }) {
        if (!(date instanceof Date)) {
            console.error("Selected date is not a valid Date object.");
            return;
        }
    
        // Adjust for the timezone by extracting the correct date parts
        const newDateFormatted = date.getFullYear() + '-' +
          String(date.getMonth() + 1).padStart(2, '0') + '-' +
          String(date.getDate()).padStart(2, '0');
    
        if (selectedDate.getFullYear() !== date.getFullYear() ||
          selectedDate.getMonth() !== date.getMonth() ||
          selectedDate.getDate() !== date.getDate()) {
          setSelectedDate(date);
          setActiveSection('date');  // Switch to the "Date Events" section
          navigate(`?date=${newDateFormatted}`);
        }
    }
    

    });

    fetchTodoInteractions(userId, selectedDate);
    fetchOverdueInteractions(userId);  // Fetch overdue interactions on page load

    return () => dp.destroy();
  }, [userId, selectedDate, navigate]);

  useEffect(() => {
    if (initialDate.toISOString().substring(0, 10) !== selectedDate.toISOString().substring(0, 10)) {
      fetchTodoInteractions(userId, selectedDate);
    }
  }, [userId, selectedDate]);

  const overdueCount = overdueInteractions.length;
  const todoCount = todoInteractions.length;

  return (
    <div className="component component-page componentToDoPage">

      <div className="section section-nav block block-ver block-nav">
        <div className='block-nav-content'>

          <div className='card block block-ver block-nav-date'>
            <div className='block block-ver block-nav-date-title'>
              <h2>{selectedDate.toLocaleDateString()}</h2>
              <p>Current Date</p>
            </div>

            <div ref={datePickerRef} />
          </div>





        </div>
      </div>

      <div className="section section-content block block-ver componentToDoPage-content">
      <div className='card block block-ver block-nav-categories'>
            <div className='block block-ver block-nav-categories-title'>
              <h2>Categories of activities</h2>
              <p>Don't forget about overdue activities</p>
            </div>
            <div className="button-group block block-hor">
              {overdueCount > 0 && (
                <button
                  className={`btn btn-secondary btn-overdue ${activeSection === 'overdue' ? 'active' : ''}`}
                  onClick={() => setActiveSection('overdue')}
                >
                  Overdue Events ({overdueCount})
                </button>
              )}
              <button
                className={`btn btn-secondary btn-date ${activeSection === 'date' ? 'active' : ''}`}
                onClick={() => setActiveSection('date')}
              >
                Date Events ({todoCount})
              </button>
            </div>
          </div>
        {activeSection === 'overdue' ? (
          <div className='component component-main componentToDoInteractionsList card project-interactions'>
            <div className='card-content'>
              <div className='interactions-list interactions-list-overdue'>
                <h3>Overdue Interactions</h3>
                <ul>
                  {overdueInteractions.map(interaction => (
                    <ToDoInteraction key={interaction._id} interaction={interaction} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          todoCount === 0 ? (
            <div className='component component-main componentToDoInteractionsList card project-interactions'>
              <div className='card-content'><p>No interactions for this date.</p></div>
            </div>
          ) : (
            <div className='component component-main componentToDoInteractionsList card project-interactions'>
              <div className='card-content'>
                <div className='interactions-list interactions-list-planned'>
                  <h3>Planned Interactions</h3>
                  <ul>
                    {todoInteractions.map(interaction => (
                      <ToDoInteraction key={interaction._id} interaction={interaction} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ToDoPage;
