import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../store';
import ClientsList from './ClientsList';
import ClientContacts from './ClientContacts';
import ProjectsList from '../projects/ProjectsList';

import { ReactComponent as ListIcon } from '../../icons/client.svg';
import { ReactComponent as ContentIcon } from '../../icons/content.svg';
import { ReactComponent as DetailsIcon } from '../../icons/person.svg';

const ClientsPage = () => {
  const { clientId } = useParams();
  const {
    visibleSection,
    setVisibleSection,
    fetchClients,
    setSelectedClient,
    clients,
    selectedClient
  } = useStore(state => ({
    visibleSection: state.visibleSection,
    setVisibleSection: state.setVisibleSection,
    fetchClients: state.fetchClients,
    setSelectedClient: state.setSelectedClient,
    clients: state.clients,
    selectedClient: state.selectedClient
  }));

  useEffect(() => {
    fetchClients();
    if (!clientId) {
      setVisibleSection('list'); // Set default section to 'list' on page load only if no clientId is present
    }
  }, [fetchClients, setVisibleSection, clientId]);

  useEffect(() => {
    if (clientId) {
      const client = clients.find(c => c._id === clientId);
      if (client) {
        setSelectedClient(client);
        setVisibleSection('content'); // Set visible section to 'content' when a client is selected
      }
    }
  }, [clientId, clients, setSelectedClient, setVisibleSection]);

  return (
    <>
      <div className={`mobile-navigation ${visibleSection}`}>
        <button className='btn' onClick={() => setVisibleSection('list')}><ListIcon /> List</button>
        <button className='btn' onClick={() => setVisibleSection('content')} disabled={!selectedClient}><ContentIcon /> Content</button>
        <button className='btn' onClick={() => setVisibleSection('details')} disabled={!selectedClient}><DetailsIcon /> Details</button>
      </div>
      {selectedClient && (
        <div className="selected-item-header">
          Selected Client: <strong>{selectedClient.name}</strong>
        </div>
      )}
      <div className={`component component-page componentClientsPage ${visibleSection}`}>
        <div className={`section section-list`}>
          <ClientsList />
        </div>
        {selectedClient && (
          <div className={`section section-content`}>
            <ProjectsList clientId={selectedClient._id} isClientsPage={true} />
          </div>
        )}
        {selectedClient && (
          <div className={`section section-details`}>
            <ClientContacts />
          </div>
        )}
      </div>
    </>
  );
};

export default ClientsPage;
