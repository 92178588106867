import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useStore from '../../store';
import ClientNew from './ClientsNew';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const ClientsList = () => {
  const clients = useStore(state => state.clients);
  const projects = useStore(state => state.projects);
  const fetchProjects = useStore(state => state.fetchProjects);
  const setSelectedClient = useStore(state => state.setSelectedClient);
  const setIsNewClient = useStore(state => state.setIsNewClient);
  const isNewClient = useStore(state => state.isNewClient);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeClientId, setActiveClientId] = useState(null);

  useEffect(() => {
    if (!projects.length) {
      fetchProjects();
    }
  }, [fetchProjects]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setActiveClientId(client._id);
  };

  const getLastActivityDays = (lastActivity) => {
    if (!lastActivity) return Infinity; // Treat never-having-had-an-activity as the maximum possible value.
    const lastActivityDate = new Date(lastActivity);
    const currentDate = new Date();
    const timeDiff = currentDate - lastActivityDate;
    return Math.floor(timeDiff / (1000 * 3600 * 24));
  };

  // Sorting clients based on the days since their last activity
  const sortedClients = clients
    .map(client => ({ ...client, daysSinceLastActivity: getLastActivityDays(client.lastActivity) }))
    .sort((a, b) => b.daysSinceLastActivity - a.daysSinceLastActivity);

    console.log('projects:');
    console.log(projects);

  return (
    <div className='component component-list componentClientsList'>
      <div className='card card-ClientsList  block block-ver'>
        <div className='block   block-hor' id='block-clients-header'>
          <h2>Clients</h2>
          {!isNewClient ? (
            <button className='btn btn-acc' onClick={() => setIsNewClient(true)}><AddIcon />Add client</button>
          ) : (
            <button className='btn btn-secondary' onClick={() => setIsNewClient(false)}><CancelIcon />Cancel</button>
          )}
        </div>
        {isNewClient && <ClientNew />}
        <div className='block ' id='block-clients-search'>
          <input
            name='clients-search'
            type="text"
            placeholder="Search clients..."
            onChange={handleSearchChange}
          />
        </div>
        <div className='block  block-ver block-list block-customer-list' id='block-clients-list'>
          {sortedClients.filter(client => client.name.toLowerCase().includes(searchTerm)).map(client => (
            <div key={client._id} className={`block   block-ver block-list-item block-customer-item block-client-item ${activeClientId === client._id ? 'block-list-item-active' : ''}`}>
              <div className='block   block-hor block-customer-1row block-client-1row'>
                <div className='block   block-ver block-item-title block-customer-title block-client-title'>
                  <Link to={`/clients/${client._id}`} onClick={() => handleClientClick(client)}>
                    {client.name}
                  </Link>

                </div>
                <div className='block block-hor block-contacts block-customer-contacts block-client-contacts'>
                  {client.phone ? (
                    <p className='contacts-item contacts-phone'>
                      <a className='btn btn-round btn-secondary' href={`tel:${client.phone}`}><PhoneIcon /></a>
                    </p>
                  ) : ''}
                  {client.email ? (
                    <p className='contacts-item contacts-email'>
                      <a className='btn btn-round btn-secondary' href={`mailto:${client.email}`}><EmailIcon /></a>
                    </p>
                  ) : ''}
                </div>
              </div>
              <div className='block   block-ver block-customer-3row block-customer-projects'>
                {projects.filter(project => project.customer._id === client._id).map(project => (
                  <div key={project._id} className='block-customer-project'>
                    <Link to={`/projects/${project._id}`}>{project.projectName}</Link>
                  </div>
                ))}
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientsList;
