import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const LeadNew = () => {
  const navigate = useNavigate();
  const addLead = useStore(state => state.addLead);
  const loggedInManagerId = useStore(state => state.userId); // Assuming this holds the logged-in manager's ID
  const userRole = useStore(state => state.role); // Assuming role is stored in the store
  const managers = useStore(state => state.managers); // Fetch managers list only once at the top
  const setIsNewLead = useStore(state => state.setIsNewLead)

  console.log('loggedInManagerId: '+loggedInManagerId);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    assignedManager: loggedInManagerId, // Default to the logged-in manager's ID
    customerType: 'lead'
  });

  // Effect to handle automatic assignment for sales managers
  useEffect(() => {
    if (userRole === 'salesManager') {
      setFormData(prev => ({ ...prev, assignedManager: loggedInManagerId }));
    }
  }, [loggedInManagerId, userRole]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.assignedManager) {
      alert('Manager assignment error.');
      return; // Prevent submission if manager isn't correctly set
    }
    addLead(formData, navigate);
  };

  return (
    <div className='component componentCustomerNew  card card-form' id='componentLeadNew'>
      <form onSubmit={handleSubmit}>
      <div class="form-group w100">
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div class="form-group w100">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div class="form-group w100">
          <label>Phone:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
        {userRole === 'boss' && (
          <div class="form-group w100">
            <label>Assigned Manager:</label>
            <select name="assignedManager" value={formData.assignedManager} onChange={handleChange} required>
              <option value="">Select a Manager</option>
              {managers.map(manager => (
                <option key={manager._id} value={manager._id}>
                  {manager.firstname} {manager.lastname}
                </option>
              ))}
            </select>
          </div>
        )}
        <div class="block   block-hor card-form-buttons">
        <button className="btn btn-secondary" type="button" onClick={() => setIsNewLead(false)}><CancelIcon />Cancel</button>
        <button className="btn btn-acc"  type="submit"><AddIcon />Create Lead</button>
        </div>
      </form>
    </div>
  );
};

export default LeadNew;
