import React, { useEffect, useState } from 'react';
import useStore from '../../store'; // Adjust the path based on your project structure
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as ClientIcon } from '../../icons/client.svg';
import { ReactComponent as AddClientIcon } from '../../icons/addclient.svg';


const ProjectsNew = ({ clientId = null }) => {
  const navigate = useNavigate();
  const { addProject, setIsNewProject, clients, fetchClients, addClient } = useStore(state => ({
    addProject: state.addProject,
    setIsNewProject: state.setIsNewProject,
    clients: state.clients,
    fetchClients: state.fetchClients,
    addClient: state.addClient,
  }));

  const initialFormData = {
    projectName: '',
    projectStatus: 'LEAD',
    startDate: new Date().toISOString().substring(0, 10), // Format as YYYY-MM-DD for input[date]
    endDate: '',
    details: '',
    customer: null,
    monthPrice: '',
    basicPrice: ''
  };

  const initialCustomerData = {
    name: '',
    email: '',
    phone: '',
    companyName: '',
    customerType: 'existing',
    assignedManager: localStorage.getItem('userId'), // Default to the current user
  };

  const [formData, setFormData] = useState(initialFormData);
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [useExistingCustomer, setUseExistingCustomer] = useState(true);

  useEffect(() => {
    if (clients.length === 0) {
      fetchClients();
    }
  }, [clients.length, fetchClients]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCustomerChange = (selectedOption) => {
    setFormData(prevState => ({
      ...prevState,
      customer: selectedOption || null // Handle clear action in react-select
    }));
  };

  const handleCustomerDataChange = (e) => {
    const { name, value } = e.target;
    setCustomerData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let customerId = formData.customer?.value;
  
    if (!useExistingCustomer) {
      if (!customerData.name || !customerData.email || !customerData.phone) {
        alert('Please fill out all customer fields');
        return;
      }
  
      try {
        const newCustomer = await addClient(customerData);
        if (newCustomer && newCustomer.client) {
          customerId = newCustomer.client._id;
        } else {
          alert('Failed to add customer, cannot create project without customer.');
          return;
        }
      } catch (error) {
        console.error('Failed to add customer:', error);
        return;
      }
    }
  
    if (!customerId) {
      alert('Failed to add customer, cannot create project without customer.');
      return;
    }
  
    const managerId = localStorage.getItem('userId');  // Get the manager ID from localStorage
  
    const submitData = {
      ...formData,
      customer: customerId,
      manager: managerId  // Include the manager ID in the submission
    };
  
    try {
      await addProject(submitData, (path, options) => navigate(path, options));
    } catch (error) {
      console.error('Failed to add project:', error);
      alert(`Failed to add project: ${error.response?.data?.message || error.message}`);
    }
  };
  



  const handleCancel = () => {
    setIsNewProject(false);
    setFormData(initialFormData); // Reset form data to initial state when cancelling
  };

  const customerOptions = clientId
    ? clients.filter(client => client._id === clientId).map(client => ({
      value: client._id,
      label: client.name
    }))
    : clients.map(client => ({
      value: client._id,
      label: client.name
    }));

  return (
    <div className='component componentProjectNew card card-form'>
      <div className='block block-ver'>
        <h2>Add new project</h2>
        <p>Lead / Contract / Chance</p>
      </div>
      <form onSubmit={handleSubmit}>

        <div className="card card-form-group">
          <h2>
            Project details
          </h2>
          <div className="form-group w100">
            <label>Project Name (domain):</label>
            <input type="text" name="projectName" value={formData.projectName} onChange={handleChange} required />
          </div>
          <div className="form-group w100">
            <label>Status:</label>
            <select name="projectStatus" value={formData.projectStatus} onChange={handleChange} required>
              <option value="LEAD">LEAD</option>
              <option value="CONTRACT">CONTRACT</option>
              <option value="CHANCE">CHANCE</option>
              
            </select>
          </div>
          <div className="form-group w100">
            <label>Details:</label>
            <textarea name="details" value={formData.details} onChange={handleChange} />
          </div>
        </div>

        <div className="card card-form-group">
          <h2>
            Customer
          </h2>
          <div className="form-group w100">
            <div className="radio-group">
              <input
                type="radio"
                id="existingCustomer"
                name="customerOption"
                checked={useExistingCustomer}
                onChange={() => setUseExistingCustomer(true)}
              />
              <label htmlFor="existingCustomer"><ClientIcon />Choose Existing Customer</label>
              <input
                type="radio"
                id="newCustomer"
                name="customerOption"
                checked={!useExistingCustomer}
                onChange={() => setUseExistingCustomer(false)}
              />
              <label htmlFor="newCustomer"><AddClientIcon />Add New Customer</label>
            </div>
            {useExistingCustomer ? (
              <Select
                options={customerOptions}
                onChange={handleCustomerChange}
                value={formData.customer}
                className="basic-single"
                classNamePrefix="select"
                name="customer"
                isClearable={!clientId} // Prevent clearing if a specific client is pre-selected
              />
            ) : (
              <>
                <div className="form-group w100">
                  <label>Name:</label>
                  <input type="text" name="name" value={customerData.name} onChange={handleCustomerDataChange} required />
                </div>
                <div className="form-group w100">
                  <label>Email:</label>
                  <input type="email" name="email" value={customerData.email} onChange={handleCustomerDataChange} required />
                </div>
                <div className="form-group w100">
                  <label>Phone:</label>
                  <input type="text" name="phone" value={customerData.phone} onChange={handleCustomerDataChange} required />
                </div>
                <div className="form-group w100">
                  <label>Company Name:</label>  {/* Add this block */}
                  <input type="text" name="companyName" value={customerData.companyName} onChange={handleCustomerDataChange} required />
                </div>
              </>
            )}
          </div>
        </div>


        <div className="card card-form-group">
          <h2>
            Prices
          </h2>
          <div className="block block-hor">
            <div className="form-group w50">
              <label>First payment:</label>
              <input type="text" name="basicPrice" value={formData.basicPrice} onChange={handleChange} />
            </div>
            <div className="form-group w50">
              <label>Monthly payment:</label>
              <input type="text" name="monthPrice" value={formData.monthPrice} onChange={handleChange} />
            </div>
          </div>

        </div>





        <div className="block block-hor card-form-buttons">
          <button className="btn btn-secondary" type="button" onClick={handleCancel}><CancelIcon />Cancel</button>
          <button className="btn btn-acc" type="submit"><AddIcon />Create Project</button>
        </div>
      </form>
    </div>
  );
};

export default ProjectsNew;
