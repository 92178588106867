import React from 'react';
import { Navigate } from 'react-router-dom';
import useStore from '../store';

const PrivateRoute = ({ element, allowedRoles }) => {
  const { token, role } = useStore(state => ({ token: state.token, role: state.role }));
  
  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to="/todo" />;
  }

  return element;
};

export default PrivateRoute;
