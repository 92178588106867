import { create } from 'zustand';
import axios from './axiosConfig';


const apiBaseUrl = process.env.REACT_APP_API_URL;


const useStore = create(set => ({
  managers: [],
  selectedManager: null,
  token: localStorage.getItem('token') || null,

  visibleSection: 'list',
  setVisibleSection: (section) => set({ visibleSection: section }),

  isNewManager: false,
  fetchManagers: async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/user/getallusers`);
      console.log('Managers fetched:', response.data); // Check the fetched data
      set({ managers: response.data });
    } catch (error) {
      console.error('Failed to fetch managers:', error);
    }
  },

  managedProjects: [],  // Update the state variable name accordingly
fetchManagedProjects: async (managerId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/api/project/manager/${managerId}`);
        console.log('Managed projects:', response.data);  // Log to verify the data
        set({ managedProjects: response.data });
    } catch (error) {
        console.error('Failed to fetch managed projects:', error);
    }
},




  setManagers: managers => set({ managers }),
  setSelectedManager: manager => set({ selectedManager: manager }),
  setIsNewManager: status => set({ isNewManager: status }),
  addManager: async (managerData) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/user/register`, managerData);
      set(state => ({ managers: [...state.managers, response.data] }));
    } catch (error) {
      console.error('Failed to add manager:', error);
    }
  },
  updateManager: async (managerData) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/api/user/update/${managerData._id}`, managerData);
      set(state => ({
        managers: state.managers.map(manager =>
          manager._id === managerData._id ? { ...manager, ...managerData } : manager
        ),
        selectedManager: state.selectedManager && state.selectedManager._id === managerData._id ? { ...state.selectedManager, ...managerData } : state.selectedManager
      }));
    } catch (error) {
      console.error('Failed to update manager:', error);
    }
  },
  removeManager: async (managerId) => {
    try {
      await axios.delete(`${apiBaseUrl}/api/user/delete/${managerId}`);
      set(state => ({
        managers: state.managers.filter(manager => manager._id !== managerId),
        selectedManager: state.selectedManager && state.selectedManager._id === managerId ? null : state.selectedManager
      }));
    } catch (error) {
      console.error('Failed to remove manager:', error);
    }
  },
  setPassword: async (managerId, newPassword) => {
    try {
      await axios.put(`${apiBaseUrl}/api/user/setpassword/${managerId}`, { newPassword });
      alert('Password updated successfully');
    } catch (error) {
      console.error('Password update failed:', error);
      alert('Password update failed');
    }
  },
  role: localStorage.getItem('userRole') || 'salesManager',
  userId: localStorage.getItem('userId') || '',
  login: async (email, password) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/auth/login`, { email, password });
      const { token, role, userId, name } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('userRole', role);
      localStorage.setItem('userId', userId);
      localStorage.setItem('userName', name);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      set({ token, role, userId, name }); // Store these in your Zustand state
      return { token, role, userId, name };
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Login failed');
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    set({ token: null });
    delete axios.defaults.headers.common['Authorization'];
  },

  emailSettings: {},

  fetchUser: async (userId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/user/email-settings/${userId}`);
      console.log('fetchUser response:', response);
      set({ emailSettings: response.data });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  },
  
  updateEmailSettings: async (settings, userId) => {
    try {
      await axios.put(`${apiBaseUrl}/api/user/update-email-settings/${userId}`, settings);
      set({ emailSettings: settings });
    } catch (error) {
      console.error('Failed to update email settings:', error);
    }
  },
  sendEmail: async (email, subject, body, managerId) => {  // Renamed and added managerId
    console.log('Attempting to send email...');
    try {
      const response = await axios.post(`${apiBaseUrl}/api/email/send`, {
        email,
        subject,
        body,
        managerId,  // Include managerId in the request
      });
      console.log('sendEmail response:', response);
      if (response.status === 200) {
        alert('Email sent successfully');
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  },
  


  /*
  CUSTOMER LOGIC
  */


  // State additions
  clients: [],
  selectedClient: null,
  isNewClient: false,

  fetchClients: async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/customer?type=existing`);
      console.log('response of fetchClients:')
      console.log(response);
      set({ clients: response.data });
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
  },

  setSelectedClient: client => set({ selectedClient: client }),
  setIsNewClient: status => set({ isNewClient: status, selectedClient: null, selectedLead: null, }),
  addClient: async (clientData) => {
    try {
      console.log('clientData at function addClient');
      console.log(clientData);

      // Ensure the assignedManager is set
      if (!clientData.assignedManager) {
        clientData.assignedManager = localStorage.getItem('userId'); // Default to the current user if not provided
      }

      const response = await axios.post(`${apiBaseUrl}/api/customer`, clientData);
      set(state => ({ clients: [...state.clients, response.data] }));

      // Return the response data and the path for navigation
      return {
        client: response.data,
        path: `/clients/${response.data._id}`,
        options: { replace: true }
      };
    } catch (error) {
      console.error('Failed to add client:', error.response ? error.response.data : error.message);

      // Check if the error is a duplicate error and handle it gracefully
      const errorMessage = error.response?.data?.message || error.message;

      if (errorMessage.includes('Duplicate')) {
        // If it's a duplicate error, try to fetch the existing customer and return it
        const existingClientResponse = await axios.get(`${apiBaseUrl}/api/customer`, {
          params: { email: clientData.email } // or use phone as another identifier
        });
        const existingClient = existingClientResponse.data[0]; // Assuming it returns a list

        // Return the existing client details to be used in the project creation
        return {
          client: existingClient,
          path: `/clients/${existingClient._id}`,
          options: { replace: true }
        };
      }

      alert(`Failed to add client: ${errorMessage}`);
      return null;
    }
  },











  updateClient: async (clientData) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/api/customer/${clientData._id}`, clientData);
      set(state => ({
        clients: state.clients.map(client =>
          client._id === clientData._id ? { ...client, ...response.data } : client
        ),
        selectedClient: state.selectedClient && state.selectedClient._id === clientData._id ? response.data : state.selectedClient
      }));
    } catch (error) {
      console.error('Failed to update client:', error);
    }
  },
  

  removeClient: async (clientId) => {
    try {
      await axios.delete(`${apiBaseUrl}/api/customer/${clientId}`);
      set(state => ({
        clients: state.clients.filter(client => client._id !== clientId),
        selectedClient: state.selectedClient && state.selectedClient._id === clientId ? null : state.selectedClient
      }));
    } catch (error) {
      console.error('Failed to remove client:', error);
    }
  },

  // Add these to your Zustand store (store.js)

  // State for leads
  leads: [],
  selectedLead: null,
  isNewLead: false,

  // Action functions for leads

  fetchLeads: async () => {
    try {
      // Determine if user is a 'boss' or a 'salesManager' and fetch leads accordingly
      const userRole = localStorage.getItem('userRole');
      const userId = localStorage.getItem('userId');

      let url = `${apiBaseUrl}/api/customer?type=lead`;
      // If the user is not a boss, adjust the URL to fetch only their assigned leads
      if (userRole !== 'boss') {
        url += `&assignedManager=${userId}`;
      }

      const response = await axios.get(url);
      set({ leads: response.data });
    } catch (error) {
      console.error('Failed to fetch leads:', error);
    }
  },




  setSelectedLead: lead => set({ selectedLead: lead }),

  setIsNewLead: status => set({ isNewLead: status }),

  addLead: async (leadData, navigate) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/customer`, leadData);
      set(state => ({
        leads: [...state.leads, response.data],
        isNewLead: false,
        selectedLead: response.data  // Set the newly added lead as the selected lead
      }));

      // Navigate to the new lead detail page
      navigate(`/leads/${response.data._id}`);

    } catch (error) {
      console.error('Failed to add lead:', error.response ? error.response.data : error.message);
    }
  },



  updateLead: async (leadData) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/api/customer/${leadData._id}`, leadData);
      set(state => ({
        leads: state.leads.map(lead =>
          lead._id === leadData._id ? { ...lead, ...leadData } : lead
        ),
        selectedLead: state.selectedLead && state.selectedLead._id === leadData._id ? { ...state.selectedLead, ...leadData } : state.selectedLead
      }));
    } catch (error) {
      console.error('Failed to update lead:', error);
    }
  },

  upgradeLeadToClient: async (leadId) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/customer/upgrade/${leadId}`);
      set(state => ({
        leads: state.leads.filter(lead => lead._id !== leadId),  // Remove the lead from leads list
        clients: [...state.clients, { ...response.data.customer }], // Add the new client to clients list if maintained
        selectedLead: null  // Optionally clear or update selectedLead if no longer needed
      }));
      alert('Lead has been upgraded to client.');
    } catch (error) {
      console.error('Failed to upgrade lead:', error);
      alert('Failed to upgrade lead to client.');
    }
  },

  removeLead: async (leadId) => {
    try {
      await axios.delete(`${apiBaseUrl}/api/customer/${leadId}`);
      set(state => ({
        leads: state.leads.filter(lead => lead._id !== leadId),
        selectedLead: state.selectedLead && state.selectedLead._id === leadId ? null : state.selectedLead
      }));
    } catch (error) {
      console.error('Failed to remove lead:', error);
    }
  },


  // Additional state inside the store definition
  interactions: [],
  setIsNewActivity: status => set({ isNewActivity: status }),
  isNewActivity: false,
  // Fetch interactions for a selected client
  // Updated fetchInteractions to use the correct API endpoint
  fetchInteractions: async (projectId) => {
    try {
      const url = `${apiBaseUrl}/api/interactions/project/${projectId}`;
      const response = await axios.get(url);
      console.log('Fetched Interactions:', response.data);
      set({ interactions: response.data });
    } catch (error) {
      console.error('Failed to fetch interactions:', error);
    }
  },



  // Adds a system interaction
  addSystemInteraction: async (projectId, details) => {
    const managerId = localStorage.getItem('userId'); // Assume managerId is stored in local storage after login
    const interactionData = {
      type: 'system',
      project: projectId,
      manager: managerId,
      details: details
    };

    console.log('interactionData at addSystemInteraction:');
    console.log(interactionData);

    try {
      const response = await axios.post(`${apiBaseUrl}/api/interactions`, interactionData);
      set(state => ({
        interactions: [...state.interactions, response.data]
      }));
    } catch (error) {
      console.error('Failed to add system interaction:', error);
    }
  },




  // New addInteraction function for projects
  // Updated addInteraction function
addInteraction: async (interactionData) => {
  try {
    const currentDate = new Date();
    const interactionDate = new Date(interactionData.date);
    const isFuture = interactionDate > currentDate;

    const response = await axios.post(`${apiBaseUrl}/api/interactions`, {
      ...interactionData,
      futureDate: isFuture ? interactionData.date : null,
      status: isFuture ? 'planned' : 'completed',
      project: interactionData.project // Ensure the project ID is included
    });

    const { managers } = useStore.getState();

    const completedInteraction = {
      ...response.data,
      manager: managers.find(m => m._id === interactionData.manager) || response.data.manager
    };

    useStore.setState(state => {
      const newInteractions = [...state.interactions, completedInteraction];
      newInteractions.sort((b, a) => new Date(a.date) - new Date(b.date)); // Ascending order
      return {
        interactions: newInteractions
      };
    });

    alert('Interaction added successfully');
  } catch (error) {
    console.error('Error adding interaction:', error);
    alert(`Failed to add interaction: ${error.response ? error.response.data.message : error.message}`);
  }
},









  // Updated editInteraction function
editInteraction: async (interactionId, interactionData) => {
  const currentDate = new Date();
  const interactionDate = new Date(interactionData.date);
  interactionData.status = interactionDate > currentDate ? 'planned' : 'completed';

  const response = await axios.put(`${apiBaseUrl}/api/interactions/${interactionId}`, interactionData);
  set(state => ({
    interactions: state.interactions.map(interaction =>
      interaction._id === interactionId ? response.data : interaction
    )
  }));
},




removeInteraction: async (interactionId) => {
  try {
      const response = await axios.delete(`${apiBaseUrl}/api/interactions/${interactionId}`);
      if (response.status === 204) {
          set(state => {
              const newTodoInteractions = state.todoInteractions.filter(interaction => interaction._id !== interactionId);
              const newOverdueInteractions = state.overdueInteractions.filter(interaction => interaction._id !== interactionId);

              return {
                  todoInteractions: newTodoInteractions,
                  overdueInteractions: newOverdueInteractions,
                  interactions: state.interactions.filter(interaction => interaction._id !== interactionId)
              };
          });
          console.log('Interaction removed successfully');
      } else {
          console.error('Failed to remove interaction. Response status:', response.status);
      }
  } catch (error) {
      console.error('Error removing interaction:', error);
      alert('Failed to remove interaction. Please try again.');
  }
},







  // Reset interactions when necessary
  clearInteractions: () => set({ interactions: [] }),




  // Project-related state and actions
  projects: [],
  selectedProject: null,
  isNewProject: false,

  fetchProjects: async () => {
    const managerId = localStorage.getItem('userId');  // Get the manager ID from localStorage
    try {
        const response = await axios.get(`${apiBaseUrl}/api/project`, {
            params: { managerId }  // Send the managerId as a query parameter
        });
        set({ projects: response.data });
    } catch (error) {
        console.error('Failed to fetch projects:', error);
    }
},


  setSelectedProject: (project) => {
    console.log(project);
    set({ selectedProject: project });
  },

  setIsNewProject: status => set({
    isNewProject: status,
    selectedProject: null,
  }),

  addProject: async (projectData, onSuccess) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/project`, projectData);
      set(state => {
        // Add the new project to the projects array and set isNewProject to false simultaneously
        const newProjects = [...state.projects, response.data];
        if (onSuccess) {
          useStore.getState().addSystemInteraction(response.data._id, `New project added: ${response.data.projectName} by manager ${localStorage.getItem('userName')}`);
          onSuccess(`/projects/${response.data._id}`, { replace: true });
        }
        alert('Project successfully added!');
        return { ...state, projects: newProjects, isNewProject: false };  // Update both projects and isNewProject state
      });
    } catch (error) {
      console.error('Failed to add project:', error.response ? error.response.data : error.message);
      alert(`Failed to add project: ${error.response ? error.response.data : error.message}`);
    }
  },




  updateProject: async (projectId, projectData) => {
    try {
      const response = await axios.put(`${apiBaseUrl}/api/project/${projectId}`, projectData);
      if (response.status === 200) {
        // Ensure that the response includes the updated project with all dates populated
        set((state) => ({
          projects: state.projects.map(p =>
            p._id === projectId ? { ...p, ...response.data } : p),
          selectedProject: response.data  // Use the fully populated response data
        }));
        console.log('Project updated successfully:', response.data);
        useStore.getState().addSystemInteraction(projectId, `Project updated: ${response.data.projectName} by manager ${localStorage.getItem('userName')}`);
      } else {
        console.error('Update project response status:', response.status);
      }
    } catch (error) {
      console.error('Failed to update project:', error);
      alert(`Failed to update project: ${error.response ? error.response.data.message : error.message}`);
    }
  },
  
  
  


  removeProject: async (projectId) => {
    try {
      const response = await axios.delete(`${apiBaseUrl}/api/project/${projectId}`);
      if (response.status === 204) {
        console.log('useStore.getState().selectedProject');
        console.log(useStore.getState().selectedProject);

        set((state) => ({
          projects: state.projects.filter(p => p._id !== projectId),
          selectedProject: null
        }));

        console.log('Project removed successfully');
      } else {
        console.error('Remove project response status:', response.status);
      }
    } catch (error) {
      console.error('Failed to remove project:', error);
      alert(`Failed to remove project: ${error.response ? error.response.data.message : error.message}`);
    }
  },



  todoInteractions: [],
  fetchTodoInteractions: async (userId, date) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/api/interactions/todo`, {
            params: {
                managerId: userId,
                date: date.toISOString()
            }
        });
        console.log('fetchTodoInteractions data:', response.data);
        set({ todoInteractions: response.data });
    } catch (error) {
        console.error('Error fetching interactions:', error);
        set({ todoInteractions: [] }); // Set to empty array on error to prevent map error
    }
},
overdueInteractions: [],
fetchOverdueInteractions: async (userId) => {
    try {
        const response = await axios.get(`${apiBaseUrl}/api/interactions/overdue`, {
            params: { managerId: userId }
        });
        console.log('fetchOverdueInteractions data:', response.data);
        set({ overdueInteractions: response.data });
    } catch (error) {
        console.error('Error fetching overdue interactions:', error);
        set({ overdueInteractions: [] }); // Set to empty array on error to prevent map error
    }
},




markInteractionComplete: async (interactionId) => {
  try {
      // Update the interaction status to 'completed' using the new route
      const response = await axios.put(`${apiBaseUrl}/api/interactions/${interactionId}/complete`);

      // Remove the completed interaction from the todoInteractions list
      set(state => ({
          todoInteractions: state.todoInteractions.filter(interaction => interaction._id !== interactionId)
      }));

      return response.data;
  } catch (error) {
      console.error('Error marking interaction as complete:', error);
      throw error;
  }
},






}));

export default useStore;
