import React, { useState, useEffect } from 'react';
import useStore from '../../store';

const EmailSettings = () => {
  const { updateEmailSettings, fetchUser, sendTestEmail } = useStore(state => ({
    updateEmailSettings: state.updateEmailSettings,
    fetchUser: state.fetchUser,
    sendTestEmail: state.sendTestEmail,
  }));

  // Get managerId from local storage
  const managerId = localStorage.getItem('userId');

  const [settings, setSettings] = useState({
    smtpServer: 'smtp.office365.com',
    smtpPort: 587,
    smtpUser: '',
    smtpPassword: '',
    showPassword: false,
  });

  const [testEmail, setTestEmail] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      if (managerId) {
        const fetchedSettings = await fetchUser(managerId);
        if (fetchedSettings) {
          setSettings({
            smtpServer: fetchedSettings.smtpServer,
            smtpPort: fetchedSettings.smtpPort,
            smtpUser: fetchedSettings.smtpUser,
            smtpPassword: '********', // Masked password
          });
        }
      }
    };

    fetchSettings();
  }, [fetchUser, managerId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevState => ({ ...prevState, [name]: value }));
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setSettings(prevState => ({
      ...prevState,
      smtpPassword: value ? value : '********',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedSettings = { ...settings };

    if (updatedSettings.smtpPassword === '********') {
      delete updatedSettings.smtpPassword;
    }

    if (managerId) {
      await updateEmailSettings(updatedSettings, managerId);
      alert('Settings updated successfully');
    } else {
      alert('Manager ID not found');
    }
  };

  const handleSendTestEmail = async (e) => {
    e.preventDefault();
    console.log('Sending test email to:', testEmail);
    if (managerId) {
      await sendTestEmail(testEmail, managerId);
    } else {
      alert('Manager ID not found');
    }
  };

  return (
    <div className='component componentEmailSettings card card-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-group w100'>
          <label>SMTP Server:</label>
          <input type="text" name="smtpServer" value={settings.smtpServer} onChange={handleChange} required />
        </div>
        <div className='form-group w100'>
          <label>SMTP Port:</label>
          <input type="number" name="smtpPort" value={settings.smtpPort} onChange={handleChange} required />
        </div>
        <div className='form-group w100'>
          <label>SMTP User (Your Outlook email):</label>
          <input type="text" name="smtpUser" value={settings.smtpUser} onChange={handleChange} required />
        </div>
        <div className='form-group w100'>
          <label>SMTP Password (Your Outlook password):</label>
          <input type="password" name="smtpPassword" value={settings.smtpPassword} onChange={handlePasswordChange} placeholder="Leave blank to keep unchanged" />
        </div>
        <button type="submit" className='btn btn-acc'>Save Settings</button>
      </form>

      <form onSubmit={handleSendTestEmail} style={{ marginTop: '20px' }}>
        <div className='form-group w100'>
          <label>Recipient Email for Test:</label>
          <input type="email" name="testEmail" value={testEmail} onChange={(e) => setTestEmail(e.target.value)} required />
        </div>
        <button type="submit" className='btn btn-acc'>Send Test Email</button>
      </form>
    </div>
  );
};

export default EmailSettings;
