import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useStore from '../store';
import Navigation from './Navigation';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';

function Header() {
  const navigate = useNavigate();
  const role = useStore(state => state.role);  // Assuming role is stored and updated in your Zustand store
  const logout = useStore(state => state.logout);

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      // Check if response and data exist; otherwise, provide a default message
      const errorMessage = error.response ? error.response.data.message : 'An unexpected error occurred';
      console.error('Login Error:', error);
    }
  };

  return (
    <div className='component componentHeader block block-hor'>
      <div className='block block-logo'>
        <h1>C<strong>R</strong>M</h1>
      </div>
      <Navigation />
      <div className='block block-account'>
        <p><a className="btn btn-secondary" onClick={handleLogout}><LogoutIcon />Logout</a></p>
      </div>
    </div>
  );
}

export default Header;
