import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../store';
import ManagersList from './ManagersList';
import ManagerContacts from './ManagerContacts';
import ManagerClientsLeadsList from './ManagerClientsLeadsList';

import { ReactComponent as ListIcon } from '../../icons/client.svg';
import { ReactComponent as ContentIcon } from '../../icons/content.svg';
import { ReactComponent as DetailsIcon } from '../../icons/person.svg';

const ManagersPage = () => {
  const { managerId } = useParams();
  const {
    visibleSection, setVisibleSection, fetchManagers, setSelectedManager, managers, selectedManager
  } = useStore(state => ({
    visibleSection: state.visibleSection,
    setVisibleSection: state.setVisibleSection,
    fetchManagers: state.fetchManagers,
    setSelectedManager: state.setSelectedManager,
    managers: state.managers,
    selectedManager: state.selectedManager
  }));

  useEffect(() => {
    fetchManagers();
    setVisibleSection('list'); // Reset to 'list' on page load or when revisiting the page
  }, [fetchManagers, setVisibleSection]);

  useEffect(() => {
    if (managerId && managers.length) {
      const manager = managers.find(m => m._id === managerId);
      setSelectedManager(manager);
    }
  }, [managerId, managers, setSelectedManager]);

  // Ensure 'content' tab is activated when a manager is selected
  useEffect(() => {
    if (selectedManager) {
      setTimeout(() => {
        setVisibleSection('content');
      }, 300);
    }
  }, [selectedManager, setVisibleSection]);

  return (
    <>
      <div className={`mobile-navigation ${visibleSection}`}>
        <button className='btn' onClick={() => setVisibleSection('list')}><ListIcon /> List</button>
        <button className='btn' onClick={() => setVisibleSection('content')} disabled={!selectedManager}><ContentIcon />Content</button>
        <button className='btn' onClick={() => setVisibleSection('details')} disabled={!selectedManager}><DetailsIcon />Details</button>
      </div>
      {selectedManager && (
        <div className="selected-item-header">
          Selected Manager: <strong>{selectedManager.firstname + ' ' + selectedManager.lastname}</strong>
        </div>
      )}
      <div className={`component component-page componentManagersPage ${visibleSection}`}>
        <div className={`section section-list`}>
          <ManagersList />
        </div>
        <div className={`section section-content`}>
          {selectedManager && (
            <>
              <ManagerClientsLeadsList managerId={selectedManager._id} />
            </>
          )}
        </div>
        <div className={`section section-details`}>
          <ManagerContacts />
        </div>
      </div>
    </>
  );
}

export default ManagersPage;
