import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useStore from '../../store';
import Last3Interactions from './Last3Interactions';
import emailIcon from '../../icons/email.svg';
import phoneCallIcon from '../../icons/phone.svg';
import meetingIcon from '../../icons/meeting.svg';
import noteIcon from '../../icons/note.svg';
import otherIcon from '../../icons/other.svg';
import systemIcon from '../../icons/system.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';

const ToDoInteraction = ({ interaction }) => {
    const { markInteractionComplete, removeInteraction, fetchTodoInteractions } = useStore();
    const [isProjectExpanded, setIsProjectExpanded] = useState(false);
    const [isPastInteractionsExpanded, setIsPastInteractionsExpanded] = useState(false);

    const icons = {
        email: emailIcon,
        phone: phoneCallIcon,
        meeting: meetingIcon,
        note: noteIcon,
        other: otherIcon,
        system: systemIcon
    };

    const backgrounds = {
        email: '--pink800',
        phone: '--blue800',
        meeting: '--green800',
        note: '--yellow800',
        other: '--grey800',
        system: '--brown800'
    };

    const handleMarkComplete = async () => {
        try {
            await markInteractionComplete(interaction._id);
            await fetchTodoInteractions(localStorage.getItem('userId'), new Date(interaction.date));  // Refetch the To Do list with the correct date
            alert('Interaction marked as completed.');
        } catch (error) {
            console.error('Error marking interaction as complete:', error);
            alert('Failed to mark interaction as completed. Please try again.');
        }
    };

    const handleRemoveInteraction = async () => {
        if (window.confirm('Are you sure you want to remove this interaction?')) {
            try {
                await removeInteraction(interaction._id);
                alert('Interaction removed successfully.');
            } catch (error) {
                console.error('Error removing interaction:', error);
                alert('Failed to remove interaction. Please try again.');
            }
        }
    };
    
    

    return (
        <li className='component component-item componentToDoInteraction'>
            <div className='block block-hor interactions-item'>
                <div className='interactions-item-img' style={{ backgroundImage: `url(${icons[interaction.type]})`, backgroundColor: `var(${backgrounds[interaction.type]})` }}>
                    {interaction.type}
                </div>
                <div className='block block-ver interactions-item-text'>
                    <div className='block block-hor interactions-item-1row'>
                        <div className='block block-ver interactions-item-title'>
                            <h4 className='interactions-item-name'>
                                <span>{interaction.type}</span> {interaction.project ? (
                                    <>
                                        (<Link to={`/projects/${interaction.project._id}`} className='interactions-item-project'>
                                            {interaction.project.projectName}
                                        </Link>)
                                    </>
                                ) : (
                                    <span>(Project not available)</span>
                                )}

                            </h4>
                            <p className='interactions-item-date'>
                                {new Date(interaction.date).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })}
                                {' '}
                                <strong>
                                    {new Date(interaction.date).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}
                                </strong>
                            </p>
                        </div>

                        {/* Customer Info */}
                        {interaction.customer && (
                            <div className='card card-todo-customer'>
                                <div className='block block-hor card-todo-customer-content'>
                                    <div className='block block-ver block-item-title block-customer-title block-client-title'>
                                        <Link to={`/clients/${interaction.customer._id}`}>
                                            {interaction.customer.customerName}
                                        </Link>
                                    </div>
                                    <div className='block block-hor block-contacts block-customer-contacts block-client-contacts'>
                                        {interaction.customer.customerPhone ? (
                                            <p className='contacts-item contacts-phone'>
                                                <a className='btn btn-round btn-secondary' href={`tel:${interaction.customer.customerPhone}`}><PhoneIcon /></a>
                                            </p>
                                        ) : ''}
                                        {interaction.customer.customerEmail ? (
                                            <p className='contacts-item contacts-email'>
                                                <a className='btn btn-round btn-secondary' href={`mailto:${interaction.customer.customerEmail}`}><EmailIcon /></a>
                                            </p>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {interaction.details && (
                        <div className='block block-ver interactions-item-details'>
                            {interaction.details}
                        </div>
                    )}

                    <div className='card block block-hor block-buttons'>
                        <button
                            className={`btn btn-secondary btn-toggle ${isPastInteractionsExpanded ? 'expanded' : ''}`}
                            onClick={() => setIsPastInteractionsExpanded(!isPastInteractionsExpanded)}
                        >
                            {isPastInteractionsExpanded ? 'Hide Last 3 Activities' : 'Show Last 3 Activities'}
                        </button>

                        {interaction.project ? (
                            <button
                                className={`btn btn-secondary btn-toggle ${isProjectExpanded ? 'expanded' : ''}`}
                                onClick={() => setIsProjectExpanded(!isProjectExpanded)}
                            >
                                {isProjectExpanded ? 'Hide Project Info' : 'Show Project Info'}
                            </button>
                        ) : ''}

                        <button
                            className='btn  btn-error'
                            onClick={handleRemoveInteraction}
                        >
                            <DeleteIcon /> Remove activity
                        </button>

                        <button
                            className='btn btn-success'
                            onClick={handleMarkComplete}
                        >
                            <SaveIcon /> Activity completed
                        </button>
                    </div>

                    {/* Project Info */}

                    {isProjectExpanded && interaction.project && (
                        <div className='card block block-hor interactions-project-details'>
                            <div className='block block-ver interactions-project-details-title'>
                                <h3>{interaction.project.projectName}</h3>
                                <p>{interaction.project.projectDescription}</p>
                            </div>
                            <div className='block block-ver interactions-project-details-infoblock'>
                                <p>project<br />
                                    status:</p> <h3>{interaction.project.projectStatus}</h3>
                            </div>
                            <div className='block block-ver interactions-project-details-infoblock'>
                                <p>first<br />
                                    payment:</p> <h3>{interaction.project.basicPrice ? `$${interaction.project.basicPrice.toFixed(2)}` : 'N/A'}</h3>
                            </div>
                            <div className='block block-ver interactions-project-details-infoblock'>
                                <p>monthly<br />
                                    payment:</p> <h3>{interaction.project.monthPrice ? `$${interaction.project.monthPrice.toFixed(2)}` : 'N/A'}</h3>
                            </div>
                        </div>
                    )}

                    {interaction.lastCompletedInteractions && interaction.lastCompletedInteractions.length > 0 && (
                        <div className='block block-ver interactions-last-activities'>
                            {isPastInteractionsExpanded && (
                                <Last3Interactions interactions={interaction.lastCompletedInteractions} />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
};

export default ToDoInteraction;
