import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import ProjectsList from './ProjectsList'; // A component for listing projects
import ProjectDetails from './ProjectDetails';
import SendEmailForm from '../email/SendEmailForm';
import ProjectNew from './ProjectNew';

import InteractionsList from '../interactions/InteractionsList';

import { ReactComponent as ListIcon } from '../../icons/client.svg';
import { ReactComponent as ContentIcon } from '../../icons/content.svg';
import { ReactComponent as DetailsIcon } from '../../icons/person.svg';

const ProjectsPage = () => {
  const { state } = useLocation(); // Get the state passed via navigate
  const { projectId } = useParams();
  const {
    visibleSection,
    setVisibleSection,
    fetchProjects,
    setSelectedProject,
    projects,
    selectedProject
  } = useStore(state => ({
    visibleSection: state.visibleSection,
    setVisibleSection: state.setVisibleSection,
    fetchProjects: state.fetchProjects,
    setSelectedProject: state.setSelectedProject,
    projects: state.projects,
    selectedProject: state.selectedProject
  }));
  const setIsNewProject = useStore(state => state.setIsNewProject);
  const isNewProject = useStore(state => state.isNewProject);

  useEffect(() => {
    if (state?.openForm) {
      console.log("Opening form because state.openForm is true");
      setIsNewProject(true); // This will open the form
    }
  }, [state, setIsNewProject]);

  useEffect(() => {
    fetchProjects();
    if (!projectId) {
      setVisibleSection('list');
    }
  }, [fetchProjects, setVisibleSection, projectId]);

  useEffect(() => {
    if (projectId) {
      const project = projects.find(p => p._id === projectId);
      if (project) {
        setSelectedProject(project);
        setVisibleSection('content');
      }
    }
  }, [projectId, projects, setSelectedProject, setVisibleSection]);

  return (
    <>
      <div className={`mobile-navigation ${visibleSection}`}>
        <button className='btn' onClick={() => setVisibleSection('list')}><ListIcon /> List</button>
        <button className='btn' onClick={() => setVisibleSection('content')} disabled={!selectedProject}><ContentIcon /> Content</button>
        <button className='btn' onClick={() => setVisibleSection('details')} disabled={!selectedProject}><DetailsIcon /> Details</button>
      </div>
      {selectedProject && (
        <div className="selected-item-header">
          Selected Project: <strong>{selectedProject.projectName}</strong>
        </div>
      )}
      <div className={`component component-page componentProjectsPage ${visibleSection}`}>
        <div className={`section section-list`}>
          <ProjectsList />
        </div>
        {isNewProject && (
          <div className={`section section-content`}>
            <ProjectNew />
          </div>
        )}
        {selectedProject && (
          <div className={`section section-content`}>
            <InteractionsList entity={selectedProject} entityId={selectedProject._id} entityName={selectedProject.projectName} />
          </div>
        )}
        {selectedProject && (
          <div className={`section section-details`}>
            <ProjectDetails project={selectedProject} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectsPage;
