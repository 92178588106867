import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as UpIcon } from '../../icons/up.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';

const LeadContacts = () => {
  const selectedLead = useStore(state => state.selectedLead);
  const updateLead = useStore(state => state.updateLead);
  const removeLead = useStore(state => state.removeLead);
  const upgradeLeadToClient = useStore(state => state.upgradeLeadToClient);
  const managers = useStore(state => state.managers);
  const role = useStore(state => state.role);
  const loggedInManagerId = useStore(state => state.userId); // This should fetch the logged-in manager's ID

  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    phone: '',
    assignedManager: '',
    customerType: 'lead'
  });

  // Ensure that assignedManager is populated for editing form
  useEffect(() => {
    if (selectedLead) {
      setEditFormData({
        name: selectedLead.name,
        email: selectedLead.email,
        phone: selectedLead.phone,
        assignedManager: selectedLead.assignedManager._id || selectedLead.assignedManager, // Ensure correct ID handling
        customerType: selectedLead.customerType
      });
    }
  }, [selectedLead]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await updateLead({ ...selectedLead, ...editFormData });
      setIsEditing(false);
    } catch (error) {
      console.error('Update failed:', error);
    }
  };
  const handleUpgradeClick = () => {
    if (window.confirm('Are you sure you want to upgrade this lead to a client?')) {
      upgradeLeadToClient(selectedLead._id);
    }
  };

  const handleRemove = async () => {
    try {
      await removeLead(selectedLead._id);
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  if (!selectedLead) {
    return <h1>Select a lead from the list</h1>;
  }

  return (
    <div className="component componentLeadContacts card">
      <div className='block block-hor block-details-header'>
        <h2>Lead details</h2>
        <div className='block block-hor block-details-buttons'>

          <button onClick={handleUpgradeClick} className='btn btn-round btn-acc'><UpIcon /></button>

          <p className='details-button details-button-edit'>
            <a className='btn btn-round btn-secondary' onClick={() => setIsEditing(true)}><EditIcon /></a>
          </p>

          <p className='details-button details-button-remove'>
            <a className='btn btn-round btn-secondary' onClick={handleRemove}><DeleteIcon /></a>
          </p>

        </div>
      </div>
      {isEditing ? (
        <div class="component  componentClientNew card card-form">
          <form onSubmit={handleSave}>
            <div class="form-group w100">
              <input type="text" name="name" value={editFormData.name} onChange={handleEditChange} />

            </div>
            <div class="form-group w100">
              <input type="email" name="email" value={editFormData.email} onChange={handleEditChange} />

            </div>
            <div class="form-group w100">
              <input type="text" name="phone" value={editFormData.phone} onChange={handleEditChange} />

            </div>

            {role === 'boss' ? (
              <div class="form-group w100">
                <select name="assignedManager" value={editFormData.assignedManager} onChange={handleEditChange}>
                  {managers.map(manager => (
                    <option key={manager._id} value={manager._id}>
                      {manager.firstname} {manager.lastname}
                    </option>
                  ))}
                </select>
              </div>

            ) : (
              <input hidden type="text" name="assignedManager" value={loggedInManagerId} readOnly />
            )}
            <div class="block   block-hor card-form-buttons">
            <button className="btn btn-secondary" type="button" onClick={() => setIsEditing(false)}><CancelIcon />Cancel</button>

              <button className="btn btn-acc" type="submit"><SaveIcon />Save</button>
              
            </div>
          </form>
        </div>
      ) : (
        <div>
          <p className='label'>Name</p>
          <h2 className='value'>{selectedLead.name}</h2>

          <p className='label'>Email</p>
          <h2 className='value'><a href={`mailto:${selectedLead.email}`}>{selectedLead.email}</a></h2>

          <p className='label'>Phone</p>
          <h2 className='value'><a href={`tel:${selectedLead.phone}`}>{selectedLead.phone}</a></h2>
          {role === 'boss' ? (<>
            <p className='label'>Manager</p>
            <h2 className='value'>
              <Link to={`/managers/${selectedLead.assignedManager._id}`}>
                {selectedLead.assignedManager.firstname} {selectedLead.assignedManager.lastname}
              </Link>
            </h2>
          </>
          ) : ('')}

          
        </div>
      )}
    </div>
  );
};

export default LeadContacts;
