import React, { useState } from 'react';
import useStore from '../../store';
import emailIcon from '../../icons/email.svg';
import phoneCallIcon from '../../icons/phone.svg';
import meetingIcon from '../../icons/meeting.svg';
import noteIcon from '../../icons/note.svg';
import otherIcon from '../../icons/other.svg';
import systemIcon from '../../icons/system.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import moment from 'moment';

const InteractionItem = ({ interaction }) => {
    const { editInteraction, removeInteraction } = useStore();
    const [isEditing, setIsEditing] = useState(false);
    const [editFormData, setEditFormData] = useState({
        type: interaction.type,
        date: interaction.date.split('T')[0],  // Extracting date portion from ISO string
        time: moment(interaction.date).format('HH:mm'),  // Extracting time portion from ISO string
        details: interaction.details,
        manager: interaction.manager._id  // Assuming manager data is populated
    });

    const icons = {
        email: emailIcon,
        phone: phoneCallIcon,
        meeting: meetingIcon,
        note: noteIcon,
        other: otherIcon,
        system: systemIcon
    };

    const backgrounds = {
        email: '--pink800',
        phone: '--blue800',
        meeting: '--green800',
        note: '--yellow800',
        other: '--grey800',
        system: '--brown800'
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;
        setEditFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = async (event) => {
        event.preventDefault();
        try {
            // Combine date and time into a single ISO string
            const combinedDateTime = moment(`${editFormData.date} ${editFormData.time}`, 'YYYY-MM-DD HH:mm').toISOString();
            
            await editInteraction(interaction._id, { ...editFormData, date: combinedDateTime });
            setIsEditing(false);
        } catch (error) {
            console.error('Update failed:', error);
        }
    };

    const handleRemove = async () => {
        if (window.confirm("Are you sure you want to delete this interaction?")) {
            try {
                await removeInteraction(interaction._id);
            } catch (error) {
                console.error('Deletion failed:', error);
            }
        }
    };

    const handleMarkComplete = async () => {
        try {
            await editInteraction(interaction._id, { ...interaction, status: 'completed' });
        } catch (error) {
            console.error('Mark complete failed:', error);
        }
    };

    return (
        <li className='component component-item componentInteractionItem'>

            <div className='block block-hor interactions-item'>

                <div className='interactions-item-img' style={{ backgroundImage: `url(${icons[interaction.type]})`, backgroundColor: `var(${backgrounds[interaction.type]})` }}>
                    {interaction.type}
                </div>

                <div className='block block-ver interactions-item-text'>
                    {isEditing ? (
                        <div className='card card-form'>
                            <form onSubmit={handleSave}>
                                <select name="type" value={editFormData.type} onChange={handleEditChange} required>
                                    <option value="">Select Type</option>
                                    <option value="email">Email</option>
                                    <option value="phone">Phone Call</option>
                                    <option value="meeting">Meeting</option>
                                    <option value="note">Note</option>
                                    <option value="other">Other</option>
                                </select>
                                <input type="date" name="date" value={editFormData.date} onChange={handleEditChange} required />
                                <input type="time" name="time" value={editFormData.time} onChange={handleEditChange} required />
                                <textarea name="details" value={editFormData.details} onChange={handleEditChange}></textarea>
                                <div className="block block-hor card-form-buttons">

                                    <button className="btn btn-secondary" onClick={() => setIsEditing(false)}><CancelIcon />Cancel</button>
                                    <button className="btn btn-acc" type="submit"><SaveIcon />Save</button>

                                </div>
                            </form>
                        </div>
                    ) : (
                        <>
                            <div className='block block-hor interactions-item-1row'>
                                <div className='block block-ver interactions-item-title'>
                                    <h4 className='interactions-item-name'><span>{interaction.type}</span> activity. Status: <b>{interaction.status}</b></h4>
                                    <p className='interactions-item-date'>
                                        {new Date(interaction.date).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}
                                        {' '}
                                        <strong>
                                            {new Date(interaction.date).toLocaleTimeString('en-GB', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            })}
                                        </strong>
                                    </p>
                                </div>

                                {interaction.type !== 'system' && (
                                    <div className="block block-hor interactions-item-buttons">
                                        <button type="button" className="btn btn-round btn-secondary" onClick={() => setIsEditing(true)}><EditIcon /></button>
                                        <button type="button" className="btn btn-round btn-secondary" onClick={handleRemove}><DeleteIcon /></button>
                                    </div>
                                )}
                            </div>
                            {interaction.details && (
                                <div className='block block-ver interactions-item-details'>
                                    {interaction.details}
                                </div>
                            )}

                            {interaction.status === 'planned' && new Date(interaction.date) < new Date() && (
                                <div className="block block-ver interactions-item-question">
                                    <p>Did the interaction happen?</p>
                                    <div className="block block-hor interactions-item-question-buttons">
                                        <button className="btn btn-error" onClick={handleRemove}><DeleteIcon />No, remove</button>
                                        <button className="btn btn-success" onClick={handleMarkComplete}><SaveIcon />Yes, save</button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

            </div>

        </li>
    );
};

export default InteractionItem;
