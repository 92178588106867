import React, { useState } from 'react';
import useStore from '../../store';  
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const ManagerNew = () => {
  const addManager = useStore(state => state.addManager);  
  const setIsNewManager = useStore(state => state.setIsNewManager)

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    role: 'salesManager',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    addManager(formData);  
  };

  return (
    <div className='component componentManagerNew card card-form'>
      {/*<h2>Add new manager</h2>*/}
      <form onSubmit={handleSubmit}>
        <div className='form-group w50'>
          <label>First Name:</label>
          <input
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group w50'>
          <label>Last Name:</label>
          <input
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group w50'>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group w50'>
          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group w100'>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div class="block   block-hor card-form-buttons">
          <button className="btn btn-secondary" type="button" onClick={() => setIsNewManager(false)}><CancelIcon />Cancel</button>
          <button type="submit" className='btn btn-acc'><AddIcon />Create Manager</button>
        </div>
      </form>
    </div>
  );
};

export default ManagerNew;
