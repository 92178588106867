import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import useStore from '../../store';

const SendEmailForm = () => {
  const { sendEmail, fetchManagedProjects, managedProjects, emailSettings, fetchUser, setSelectedProject, selectedProject } = useStore(state => ({
    sendEmail: state.sendEmail,
    fetchManagedProjects: state.fetchManagedProjects,
    managedProjects: state.managedProjects,
    emailSettings: state.emailSettings,
    fetchUser: state.fetchUser,
    setSelectedProject: state.setSelectedProject,
    selectedProject: state.selectedProject,
  }));

  const managerId = localStorage.getItem('userId');

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedProjectOption, setSelectedProjectOption] = useState(null);

  useEffect(() => {
    // Fetch SMTP settings and projects when the component mounts
    if (managerId) {
      fetchUser(managerId);
      fetchManagedProjects(managerId);
    }
  }, [fetchUser, fetchManagedProjects, managerId]);

  const handleProjectChange = (option) => {
    setSelectedProjectOption(option);
    setSelectedProject(option.value); // Set the selected project in Zustand
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (selectedProject && selectedProject.customer) {
      setIsSending(true);
      try {
        await sendEmail(selectedProject.customer.email, subject, body, managerId);
        alert('Email sent successfully');
      } catch (error) {
        console.error('Failed to send email:', error);
        alert('Failed to send email');
      } finally {
        setIsSending(false);
      }
    } else {
      alert('Please select a project first.');
    }
  };

  if (!emailSettings.smtpServer || !emailSettings.smtpUser) {
    return <p>At first, you need to set up SMTP data in email settings.</p>;
  }

  const projectOptions = managedProjects.map(project => ({
    value: project,
    label: project.projectName,
  }));

  return (
    <div className='component componentSendEmailForm card'>
      <h3>Send Email</h3>
      <form onSubmit={handleSendEmail}>
        <div className='form-group w100'>
          <label>Select Project:</label>
          <Select
            value={selectedProjectOption}
            onChange={handleProjectChange}
            options={projectOptions}
            placeholder="Select a project"
          />
        </div>

        {selectedProject && selectedProject.customer && (
          <div className='form-group w100'>
            <p><strong>Selected Project:</strong> {selectedProject.projectName}</p>
            <p><strong>Client Name:</strong> {selectedProject.customer.name}</p>
            <p><strong>Client Email:</strong> {selectedProject.customer.email}</p>
          </div>
        )}

        {selectedProject && selectedProject.customer && (
          <>
            <div className='form-group w100'>
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <div className='form-group w100'>
              <label>Message:</label>
              <textarea
                name="body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                required
              />
            </div>
            <button type="submit" className='btn btn-acc' disabled={isSending}>
              {isSending ? 'Sending...' : 'Send Email'}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default SendEmailForm;
