import React, { useEffect } from 'react';
import useStore from '../../store';
import InteractionItem from './InteractionItem';
import InteractionForm from '../interactions/InteractionForm';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';


const InteractionsList = ({ entity, entityId, entityName }) => {
  const { interactions, fetchInteractions } = useStore();
  const setIsNewActivity = useStore(state => state.setIsNewActivity);
  const isNewActivity = useStore(state => state.isNewActivity)

  useEffect(() => {
    if (entityId) {
      fetchInteractions(entityId);
    }
  }, [entityId, fetchInteractions]);

  // Current date for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to start of the day to avoid time zone issues

  const plannedInteractions = interactions.filter(inter =>
    new Date(inter.date) >= today && inter.status === 'planned'
  );

  const overdueInteractions = interactions.filter(inter =>
    new Date(inter.date) < today && inter.status === 'planned'
  );

  const completedInteractions = interactions.filter(inter =>
    inter.status === 'completed'
  );


  return (
    <div className='component component-main componentInteractionsList card'>
      <div className='block  block-hor' id='block-Interactions-header'>
        <h2>Activities log</h2>
        {isNewActivity ? (
          <button className='btn btn-secondary' onClick={() => setIsNewActivity(false)}><CancelIcon />Cancel</button>
        ) :
          (
            <button className='btn btn-acc' onClick={() => setIsNewActivity(true)}><AddIcon />Add activity</button>
          )}

      </div>
      {isNewActivity && <InteractionForm />}


      <div className='card' id='card-Interactions'>
        <div className='card-content'>


          {overdueInteractions.length > 0 &&

            <div className='interactions-list interactions-list-overdue'>
              <h3>Overdue Interactions</h3>
              <ul>
                {overdueInteractions.map((interaction, index) => (
                  <InteractionItem key={interaction._id || index} interaction={interaction} />
                ))}
              </ul>
            </div>
          }


          {plannedInteractions.length > 0 &&
            <div className='interactions-list interactions-list-planned'>
              <h3>Planned Interactions</h3>
              <ul>
                {plannedInteractions.map((interaction, index) => (
                  <InteractionItem key={interaction._id || index} interaction={interaction} />
                ))}
              </ul>
            </div>
          }

          {/* Completed Interactions */}
          {completedInteractions.length > 0 &&
          <div className='interactions-list interactions-list-complete'>
            <h3>Completed Interactions</h3>
            <ul>
              {completedInteractions.map((interaction, index) => (
                <InteractionItem key={interaction._id || index} interaction={interaction} />
              ))}
            </ul>
          </div>
          }
        </div>
      </div>




    </div>
  );
};

export default InteractionsList;
