import React from 'react';
import moment from 'moment';
import useStore from '../../store';
import emailIcon from '../../icons/email.svg';
import phoneCallIcon from '../../icons/phone.svg';
import meetingIcon from '../../icons/meeting.svg';
import noteIcon from '../../icons/note.svg';
import otherIcon from '../../icons/other.svg';
import systemIcon from '../../icons/system.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const Last3Interactions = ({ interactions }) => {
    const { editInteraction, removeInteraction } = useStore();

    return (
        <div className='component component-list componentLast3Interactions card'>
            <div className='card-content'>
                <div className='interactions-list interactions-list-last'>
                    <ul>
                        {interactions.slice(0, 3).map((interaction, index) => (
                            <li key={interaction._id || index} className='component component-item componentInteractionItem'>
                                <div className='block block-hor interactions-item'>
                                    <div className='interactions-item-img' style={{ backgroundImage: `url(${{
                                        email: emailIcon,
                                        phone: phoneCallIcon,
                                        meeting: meetingIcon,
                                        note: noteIcon,
                                        other: otherIcon,
                                        system: systemIcon
                                    }[interaction.type]})`, backgroundColor: `var(${{
                                        email: '--pink800',
                                        phone: '--blue800',
                                        meeting: '--green800',
                                        note: '--yellow800',
                                        other: '--grey800',
                                        system: '--brown800'
                                    }[interaction.type]})` }}>
                                        {interaction.type}
                                    </div>

                                    <div className='block block-ver interactions-item-text'>
                                        <div className='block block-hor interactions-item-1row'>
                                            <div className='block block-ver interactions-item-title'>
                                                <h4 className='interactions-item-name'>
                                                    <span>{interaction.type}</span> activity
                                                </h4>
                                                <p className='interactions-item-date'>
                                                    {new Date(interaction.date).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric'
                                                    })}
                                                    {' '}
                                                    <strong>
                                                        {new Date(interaction.date).toLocaleTimeString('en-GB', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: false
                                                        })}
                                                    </strong>
                                                </p>
                                            </div>

                                            
                                        </div>
                                        {interaction.details && (
                                            <div className='block block-ver interactions-item-details'>
                                                {interaction.details}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Last3Interactions;
