import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';

const ClientContacts = () => {
  const navigate = useNavigate();
  const selectedClient = useStore(state => state.selectedClient);
  const updateClient = useStore(state => state.updateClient);
  const removeClient = useStore(state => state.removeClient);
  const managers = useStore(state => state.managers);
  const role = useStore(state => state.role);

  const [isEditing, setIsEditing] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    phone: '',
    assignedManager: '',
    companyName: '',
    customerType: 'existing'
  });

  useEffect(() => {
    if (selectedClient) {
      setEditFormData({
        _id: selectedClient._id,
        name: selectedClient.name,
        email: selectedClient.email,
        phone: selectedClient.phone,
        companyName: selectedClient.companyName,
        assignedManager: selectedClient.assignedManager._id,  // Store only the ID
        customerType: selectedClient.customerType
      });
    }
  }, [selectedClient]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const updatedData = { ...editFormData };

    // If the user is a manager, retain the original assigned manager
    if (role !== 'boss') {
      updatedData.assignedManager = selectedClient.assignedManager._id;
    }

    try {
      await updateClient(updatedData);
      setIsEditing(false);
    } catch (error) {
      console.error('Update failed:', error);
    }
  };

  const handleRemove = async () => {
    try {
      await removeClient(selectedClient._id);
      navigate('/clients');
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  if (!selectedClient) {
    return <h1>Select a client from the list</h1>;
  }

  return (
    <div className="component componentClientContacts card">
      <div className='block block-hor block-details-header'>
        <h2>Client details</h2>
        <div className='block block-hor block-details-buttons'>
          <p className='details-button details-button-edit'>
            <a className='btn btn-round btn-secondary' onClick={() => setIsEditing(true)}><EditIcon /></a>
          </p>
          <p className='details-button details-button-remove'>
            <a className='btn btn-round btn-secondary' onClick={handleRemove}><DeleteIcon /></a>
          </p>
        </div>
      </div>

      {isEditing ? (
        <div className="component componentClientNew card card-form">
          <form onSubmit={handleSave}>
            <div className="form-group w100">
              <input type="text" name="name" value={editFormData.name} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <input type="email" name="email" value={editFormData.email} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <input type="text" name="phone" value={editFormData.phone} onChange={handleEditChange} />
            </div>
            <div className="form-group w100">
              <label>Company Name:</label>
              <input type="text" name="companyName" value={editFormData.companyName} onChange={handleEditChange} />
            </div>
            {/* Conditionally render the Assigned Manager field */}
            {role === 'boss' && (
              <div className="form-group w100">
                <select name="assignedManager" value={editFormData.assignedManager} onChange={handleEditChange}>
                  <option value="">Select a Manager</option>
                  {managers.map(manager => (
                    <option key={manager._id} value={manager._id}>
                      {manager.firstname} {manager.lastname}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="block block-hor card-form-buttons">
              <button className="btn btn-secondary" type="button" onClick={() => setIsEditing(false)}><CancelIcon />Cancel</button>
              <button className="btn btn-acc" type="submit"><SaveIcon />Save</button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <p className='label'>Name</p>
          <h2 className='value'>{selectedClient.name}</h2>
          <p className='label'>Email</p>
          <h2 className='value'><a href={`mailto:${selectedClient.email}`}>{selectedClient.email}</a></h2>
          <p className='label'>Phone</p>
          <h2 className='value'><a href={`tel:${selectedClient.phone}`}>{selectedClient.phone}</a></h2>
          <p className='label'>Company Name</p>
          <h2 className='value'>{selectedClient.companyName}</h2>
          {role === 'boss' && (
            <div>
              <p className='label'>Manager</p>
              <h2 className='value'>
                <Link to={`/managers/${selectedClient.assignedManager._id}`}>
                  {selectedClient.assignedManager.firstname} {selectedClient.assignedManager.lastname}
                </Link>
              </h2>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientContacts;
