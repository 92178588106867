import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';
import { ReactComponent as ClientIcon } from '../../icons/client.svg';

const ProjectsList = ({ clientId = null, isClientsPage = false }) => {
  const navigate = useNavigate();
  const projects = useStore(state => state.projects);
  const fetchProjects = useStore(state => state.fetchProjects);
  const setIsNewProject = useStore(state => state.setIsNewProject);
  const isNewProject = useStore(state => state.isNewProject);
  const setSelectedProject = useStore(state => state.setSelectedProject);
  const [searchTerm, setSearchTerm] = useState('');
  const selectedProject = useStore(state => state.selectedProject);
  const [selectedStatus, setSelectedStatus] = useState(['LEAD', 'CONTRACT', 'CHANCE']); // Default to show all statuses

  useEffect(() => {
    if (!projects.length) {
      fetchProjects();
    }
  }, [fetchProjects]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleProjectClick = (project, event) => {
    event.preventDefault();
    setSelectedProject(project);
    navigate(`/projects/${project._id}`);
  };

  const handleAddProjectClick = () => {
    if (isClientsPage) {
      console.log("Redirecting to Projects page with openForm state");
      navigate('/projects', { state: { openForm: true } });
    } else {
      setIsNewProject(true);
    }
  };
  
  
  

  const formatDateTime = (date) => {
    const optionsDate = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    const formattedDate = new Date(date).toLocaleDateString('en-GB', optionsDate).replace(/\//g, '.');
    const formattedTime = new Date(date).toLocaleTimeString('en-GB', optionsTime);

    return { formattedDate, formattedTime };
  };

  const filteredProjects = clientId
    ? projects.filter(project => project.customer._id === clientId)
    : projects;

  const sortedProjects = filteredProjects
    .filter(project =>
      project.projectName.toLowerCase().includes(searchTerm) &&
      selectedStatus.includes(project.projectStatus) // Filter by selected status
    )
    .sort((a, b) => {
      const dateA = new Date(a.lastCompletedInteractionDate);
      const dateB = new Date(b.lastCompletedInteractionDate);
      return dateB - dateA; // Sort in descending order (latest date at the top)
    });

  const getDaysColorClass = (days) => {
    if (days === null) return 'no-activity';
    if (days <= 30) return 'green';
    if (days <= 90) return 'yellow';
    if (days > 90) return 'red';
    return '';
  };

  const handleStatusChange = (status) => {
    setSelectedStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status) // Remove status if already selected
        : [...prev, status] // Add status if not selected
    );
  };

  return (
    <div className='component component-list componentProjectsList'>
      <div className='card card-ProjectsList block block-ver'>
        <div className='block block-hor' id='block-projects-header'>
          <h2>Projects</h2>
          {!isNewProject ? (
            <button className='btn btn-acc' onClick={handleAddProjectClick}><AddIcon />Add project</button>
          ) : (
            <button className='btn btn-secondary' onClick={() => setIsNewProject(false)}><CancelIcon />Cancel</button>
          )}
        </div>

        {clientId ? '' : (
          <div className='block' id='block-projects-search'>
            <input
              name='projects-search'
              type="text"
              placeholder="Search projects..."
              onChange={handleSearchChange}
            />
          </div>
        )}

        <div className='block' id='block-projects-filters'>
          <label>
            <input
              type="checkbox"
              checked={selectedStatus.includes('LEAD')}
              onChange={() => handleStatusChange('LEAD')}
            />
            Lead
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedStatus.includes('CONTRACT')}
              onChange={() => handleStatusChange('CONTRACT')}
            />
            Contract
          </label>
          <label>
            <input
              type="checkbox"
              checked={selectedStatus.includes('CHANCE')}
              onChange={() => handleStatusChange('CHANCE')}
            />
            Chance
          </label>
        </div>

        <div className='block block-ver block-list block-project-list' id='block-projects-list'>
          {sortedProjects.map(project => {
            const daysSinceLastActivity = project.lastCompletedInteractionDate
              ? Math.floor((new Date() - new Date(project.lastCompletedInteractionDate)) / (1000 * 60 * 60 * 24))
              : null;

            const daysColorClass = getDaysColorClass(daysSinceLastActivity);

            return (
              <div key={project._id} className={`block block-ver block-list-item block-project-item ${selectedProject && selectedProject._id === project._id ? 'active' : ''}`}
                onClick={(e) => handleProjectClick(project, e)}>

                <div className='block block-hor block-project-1row'>
                  <div className='block block-ver block-item-title block-project-title'>
                    <p className='item-name' onClick={(e) => handleProjectClick(project, e)}>
                      {project.projectName}
                    </p>
                    <p className='item-customer' onClick={(e) => handleProjectClick(project, e)}>
                      <ClientIcon /> {project.customer.name}
                    </p>
                  </div>
                </div>

                <div className='block-count block-project-dates'>
                  <p className='count-item count-status'>
                    Status:<br />
                    <b>{project.projectStatus}</b>
                  </p>
                  <p className='count-item count-leads'>
                    Last action:<br />
                    <b>
                      {project.lastCompletedInteractionDate ? (
                        <>
                          <span className="date">{formatDateTime(project.lastCompletedInteractionDate).formattedDate}</span>
                          <br />
                          <span className="time">{formatDateTime(project.lastCompletedInteractionDate).formattedTime}</span>
                        </>
                      ) : '-'}
                    </b>
                  </p>

                  <p className={`count-item count-days-since ${daysColorClass}`}>
                    Since last action:<br />
                    <b>{daysSinceLastActivity !== null ? `${daysSinceLastActivity} days` : '-'}</b>
                  </p>

                  <p className='count-item count-clients'>
                    Next action:<br />
                    <b>
                      {project.nextPlannedInteractionDate ? (
                        <>
                          <span className="date">{formatDateTime(project.nextPlannedInteractionDate).formattedDate}</span>
                          <br />
                          <span className="time">{formatDateTime(project.nextPlannedInteractionDate).formattedTime}</span>
                        </>
                      ) : '-'}
                    </b>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectsList;
