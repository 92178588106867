// src/features/leads/LeadsList.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useStore from '../../store';
import LeadNew from './LeadsNew';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const LeadsList = () => {
  const leads = useStore(state => state.leads);
  const setSelectedLead = useStore(state => state.setSelectedLead);
  const setIsNewLead = useStore(state => state.setIsNewLead)
  const isNewLead = useStore(state => state.isNewLead)
  const [searchTerm, setSearchTerm] = useState('');
  const [activeLeadId, setActiveLeadId] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleLeadSelect = (lead) => {
    setSelectedLead(lead);
    setActiveLeadId(lead._id);
  };

  return (
    <div className='component component-list componentLeadsList '>
      <div className='card card-LeadsList  block block-ver'>
        <div className='block   block-hor' id='block-leads-header'>
          <h2>Leads</h2>
          {!isNewLead ? (
            <button className='btn btn-acc' onClick={() => setIsNewLead(true)}><AddIcon />Add lead</button>
          ) : (
            <button className='btn btn-secondary' onClick={() => setIsNewLead(false)}><CancelIcon />Cancel</button>
          )}

        </div>

        {isNewLead && <LeadNew />}
        <div className='block ' id='block-leads-search'>
          <input
            name='leads-search'
            type="text"
            placeholder="Search leads..."
            onChange={handleSearchChange}
          />
        </div>




        <div className='block  block-ver block-list block-leads-list' id='block-leads-list'>
          {leads.filter(lead => lead.name.toLowerCase().includes(searchTerm)).length > 0 ? (
            leads.filter(lead => lead.name.toLowerCase().includes(searchTerm)).map(lead => (
              <div key={lead._id} className={`block   block-ver block-list-item block-customer-item block-lead-item ${activeLeadId === lead._id ? 'block-list-item-active' : ''}`}>
                <div className='block   block-hor block-customer-1row block-lead-1row'>
                  <div className='block   block-ver block-item-title block-customer-title block-lead-title'>
                    <Link to={`/leads/${lead._id}`} onClick={() => handleLeadSelect(lead)}>
                      {lead.name}
                    </Link>
                    <div className=' block-count block-customer-count block-lead-count'>

                      <p className='count-item count-activities'>Activities: <b>{lead.totalInteractions}</b></p>

                      {lead.overdueInteractions ? (
                        <p className='count-item count-overdue'>Overdue: <b>{lead.overdueInteractions}</b></p>
                      ) : ''}
                    </div>
                  </div>
                  <div className='block block-hor block-contacts block-customer-contacts block-lead-contacts'>
                    {lead.phone ? (
                      <p className='contacts-item contacts-phone'>
                        <a className='btn btn-round btn-secondary' href={`tel:${lead.phone}`}><PhoneIcon /></a>
                      </p>
                    ) : ''}
                    {lead.email ? (
                      <p className='contacts-item contacts-email'>
                        <a className='btn btn-round btn-secondary' href={`mailto:${lead.email}`}><EmailIcon /></a>
                      </p>
                    ) : ''}
                  </div>
                </div>
                <div className='block   block-hor block-customer-2row block-lead-2row'>
                  <div className='block-stats block-customer-stats block-lead-stats'>

                    <p className='stats-item stats-item-manager'>Manager:<br /><b>{lead.managerName}</b></p>

                    <p className='stats-item stats-item-last'>Last activity:<br /><b>{lead.lastActivity ? new Date(lead.lastActivity).toLocaleDateString() : '-'}</b></p>

                    <p className='stats-item stats-item-next'>Next activity:<br /><b>{lead.nextActivity ? new Date(lead.nextActivity).toLocaleDateString() : '-'}</b></p>


                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-items-message">
              There are no leads matching your search. Click on 'Add Lead' above to create new leads.
            </p>
          )}
        </div>


      </div>
    </div>
  );
};

export default LeadsList;
