import React from 'react';
import { NavLink } from 'react-router-dom';
import useStore from '../store';
import { ReactComponent as ManagerIcon } from '../icons/manager.svg';
import { ReactComponent as LeadIcon } from '../icons/lead.svg';
import { ReactComponent as ClientIcon } from '../icons/client.svg';
import { ReactComponent as TodoIcon } from '../icons/todo.svg';
import { ReactComponent as EmailIcon } from '../icons/email.svg'; // New icon for emails

function Navigation() {
  const role = useStore(state => state.role);

  return (
    <nav>
      <ul>
        {(role === 'boss' || role === 'salesManager') && (
          <>
            <li>
              <NavLink to="/todo" activeClassName="active">
                <TodoIcon />To Do
              </NavLink>
            </li>

            <li>
              <NavLink to="/projects" activeClassName="active">
                <LeadIcon />Projects
              </NavLink>
            </li>

            <li>
              <NavLink to="/emails" activeClassName="active"> {/* New link to Emails page */}
                <EmailIcon />Emails
              </NavLink>
            </li>
          </>
        )}

        {role === 'boss' && (
          <li>
            <NavLink to="/managers" activeClassName="active">
              <ManagerIcon />Managers
            </NavLink>
          </li>
        )}

        {(role === 'boss' || role === 'salesManager') && (
          <li>
            <NavLink to="/clients" activeClassName="active">
              <ClientIcon />Clients
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;
