import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useStore from '../../store';
import ManagerNew from './ManagerNew';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';

const ManagersList = () => {
  const managers = useStore(state => state.managers);
  const setSelectedManager = useStore(state => state.setSelectedManager);
  const setIsNewManager = useStore(state => state.setIsNewManager);
  const isNewManager = useStore(state => state.isNewManager);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeManagerId, setActiveManagerId] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleManagerSelect = (manager) => {
    setSelectedManager(manager);
    setActiveManagerId(manager._id);
  };

  const filteredManagers = managers.filter(manager =>
    manager.firstname.toLowerCase().includes(searchTerm) ||
    manager.lastname.toLowerCase().includes(searchTerm) ||
    manager.email.toLowerCase().includes(searchTerm)
  );

  return (
    <div className='component component-list componentManagersList '>
      <div className='card card-ManagersList block block-ver'>
        <div className='block block-hor' id='block-managers-header'>
          <h2>Employees</h2>
          {!isNewManager ? (
            <button className='btn btn-acc' onClick={() => setIsNewManager(true)}><AddIcon />Add manager</button>
          ) : (
            <button className='btn btn-secondary' onClick={() => setIsNewManager(false)}><CancelIcon />Cancel</button>
          )}
        </div>
        {isNewManager && <ManagerNew />}
        <div className='block ' id='block-managers-search'>
          <input
            name='managers-search'
            type="text"
            placeholder="Search managers..."
            onChange={handleSearchChange}
          />
        </div>

        <div className='block block-ver block-list' id='block-managers-list'>
          {filteredManagers.length > 0 ? filteredManagers.map(manager => (
            <div key={manager._id} className={`block block-hor block-list-item block-manager-item ${activeManagerId === manager._id ? 'block-list-item-active' : ''}`}>
              <div className='block block-ver block-item-title block-manager-title'>
                <Link to={`/managers/${manager._id}`} onClick={() => handleManagerSelect(manager)}>
                  {manager.firstname} {manager.lastname}
                </Link>
                <div className='block-count block-manager-count'>
                  <p className='count-item count-chances'>Chances:<br /><b>{manager.chanceCount}</b></p>
                  <p className='count-item count-leads'>Leads:<br /><b>{manager.leadsCount}</b></p>
                  <p className='count-item count-contracts'>Contracts:<br /><b>{manager.contractsCount}</b></p>
                </div>
              </div>
              <div className='block block-hor block-contacts block-manager-contacts'>
                {manager.phone ? (
                  <p className='contacts-item contacts-phone'>
                    <a className='btn btn-round btn-secondary' href={`tel:${manager.phone}`}><PhoneIcon/></a>
                  </p>
                ) : ''}
                {manager.email ? (
                  <p className='contacts-item contacts-email'>
                    <a className='btn btn-round btn-secondary' href={`mailto:${manager.email}`} ><EmailIcon/></a>
                  </p>
                ) : ''}
              </div>
            </div>
          )) : (
            <p>No managers found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagersList;
