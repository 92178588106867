// src/features/clients/ClientNew.js
import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg';



const ClientNew = () => {
  const navigate = useNavigate();
  const addClient = useStore(state => state.addClient);
  const setIsNewClient = useStore(state => state.setIsNewClient)

  const managers = useStore(state => state.managers); // Assuming you have this array in your store
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    assignedManager: '',
    companyName: '',
    customerType: 'existing'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.assignedManager) {
      alert('Please select a valid manager');
      return; // Prevent submission if no manager is selected
    }

    const result = await addClient(formData);

    if (result) {
      setIsNewClient(false);
      // If the client was successfully added, notify the user and navigate away
      alert('Client added successfully!');

      // Use the path and options returned by addClient for navigation
      navigate(result.path, result.options);
    } else {
      // If there was an error, notify the user
      // alert('Failed to add client. Please correct the errors and try again.');
    }
  };




  useEffect(() => {
    const currentUserId = localStorage.getItem('userId');
    setFormData(f => ({ ...f, assignedManager: currentUserId }));
  }, []);



  return (
    <div className='component componentCustomerNew card card-form' id='componentClientNew'>
      <form onSubmit={handleSubmit}>
        <div className="form-group w100">
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group w100">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group w100">
          <label>Phone:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>
        <div className="form-group w100">
          <label>Company Name:</label>
          <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} required />
        </div>
        <div className="form-group w100 hidden">
          <label>Assigned Manager:</label>
          <select name="assignedManager" value={formData.assignedManager} onChange={handleChange} required>
            {managers.filter(manager => manager.role === 'boss').length > 0 ? (
              managers.filter(manager => manager.role === 'boss').map(filteredManager => (
                <option key={filteredManager._id} value={filteredManager._id}>
                  {filteredManager.firstname} {filteredManager.lastname}
                </option>
              ))
            ) : (
              <option value="">No available managers</option>
            )}
          </select>

        </div>
        <div className="form-group w100 hidden">
          <label>Customer Type:</label>
          <select name="customerType" value={formData.customerType} onChange={handleChange} required>
            <option defaultValue value="existing">Client</option>
          </select>
        </div>
        <div className="block   block-hor card-form-buttons">
          <button className="btn btn-secondary" type="button" onClick={() => setIsNewClient(false)}><CancelIcon />Cancel</button>
          <button className="btn btn-acc" type="submit"><AddIcon />Create Client</button>
        </div>
      </form>
    </div>
  );
};

export default ClientNew;
