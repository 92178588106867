import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store';
import { ReactComponent as LoginIcon } from '../icons/login.svg';

const LoginForm = () => {
  const navigate = useNavigate();
  const login = useStore(state => state.login);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Added state to store the error message

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login(email, password);
      navigate('/todo'); // Navigate to Managers page after login
    } catch (error) {
      // Check if response and data exist; otherwise, provide a default message
      const errorMessage = error.response ? error.response.data.message : 'An unexpected error occurred';
      setErrorMessage(errorMessage);
      console.error('Login Error:', error);
    }
  };


  return (
    <div className='component componentLoginForm'>
      <div className='card card-form'>
        <form className='' onSubmit={handleSubmit}>
          <div className='block block-logo'>
            <h1>C<strong>R</strong>M</h1>
          </div>


          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* Display error message */}
          <div className="form-group w100">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group w100">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="block block-hor card-form-buttons">
            <button type="submit" className="btn btn-acc"><LoginIcon />Login</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default LoginForm;
