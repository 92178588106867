import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import LoginForm from './components/LoginForm';
import Header from './components/Header';
import ManagersPage from './features/managers/ManagersPage';

import EmailSettings from './features/email/EmailSettings';
import EmailsPage from './features/email/EmailPage';
import PrivateRoute from './components/PrivateRoute';
import ClientsPage from './features/clients/ClientsPage';
import ToDoPage from './features/todo/ToDoPage';

import LeadsPage from './features/leads/LeadsPage';
import ProjectsPage from './features/projects/ProjectPage';
import FloatingShapes from './components/FloatingShapes';

import useStore from './store';

function App() {
  const { token, fetchManagers } = useStore(state => ({ token: state.token, fetchManagers: state.fetchManagers }));

  useEffect(() => {
    if (token) {
      fetchManagers();
    }
  }, [token, fetchManagers]);

  return (
    <>
      <FloatingShapes />
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap" rel="stylesheet" />
      </Helmet>
      <Router>
        <div className='component componentApp'>
          {token && <Header />}

          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/managers" element={<PrivateRoute element={<ManagersPage />} allowedRoles={['boss']} />} />

            <Route path="/clients" element={<PrivateRoute element={<ClientsPage />} allowedRoles={['boss', 'salesManager']} />} />
            <Route path="/clients/:clientId" element={<PrivateRoute element={<ClientsPage />} allowedRoles={['boss', 'salesManager']} />} />


            <Route path="/leads" element={<PrivateRoute element={<LeadsPage />} allowedRoles={['boss', 'salesManager']} />} />
            <Route path="/leads/:leadId" element={<PrivateRoute element={<LeadsPage />} allowedRoles={['boss', 'salesManager']} />} />

            <Route path="/managers/:managerId" element={<PrivateRoute element={<ManagersPage />} allowedRoles={['boss']} />} />
            <Route path="/managers/:managerId/emailsettings" element={<PrivateRoute element={<EmailSettings />} allowedRoles={['boss', 'salesManager']} />} />
            <Route path="/projects" element={<PrivateRoute element={<ProjectsPage />} allowedRoles={['boss', 'salesManager']} />} />
            <Route path="/projects/:projectId" element={<PrivateRoute element={<ProjectsPage />} allowedRoles={['boss', 'salesManager']} />} />
            <Route path="/todo" element={<PrivateRoute element={<ToDoPage />} allowedRoles={['boss', 'salesManager']} />} />
          
            <Route path="/emails" element={<PrivateRoute element={<EmailsPage />} allowedRoles={['boss', 'salesManager']} />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
