import React, { useEffect, useState } from 'react';
import { ReactComponent as Shape1 } from './../icons/Ellipse1.svg';
import { ReactComponent as Shape2 } from './../icons/Ellipse2.svg';
import { ReactComponent as Shape3 } from './../icons/Ellipse3.svg';
import { ReactComponent as Shape4 } from './../icons/Ellipse4.svg';

const shapes = [Shape1, Shape2, Shape3, Shape4];

const FloatingShapes = () => {
  const [currentShapes, setCurrentShapes] = useState([]);

  useEffect(() => {
    const addShape = () => {
      const ShapeComponent = shapes[Math.floor(Math.random() * shapes.length)];
      const key = Date.now();
      const clsName = `shapeAnim${Math.floor(Math.random() * 10) + 1}`; // Randomly pick an animation
      const initialScale = 1.5 + Math.random() * 3; // Scale between 0.5 and 1.5
      const rotation = Math.random() * 360; // Random rotation between 0 and 360 degrees

      const style = {
        position: 'fixed',
        left: `${ (Math.random() * window.innerWidth) - (window.innerWidth/4) }px`,
        top: `${(Math.random() * window.innerHeight) - (window.innerHeight/4)}px`,
        opacity: 0,
        transition: 'opacity 3s ease-in-out',
        pointerEvents: 'none',
        transform: `rotate(${rotation}deg) scale(${initialScale})`
      };

      const svgStyle = {
        
      };

      const newShape = {
        id: key,
        element: <ShapeComponent style={svgStyle} />,
        containerStyle: style,
        clsName: clsName
      };

      setCurrentShapes(prev => [...prev, newShape]);

      setTimeout(() => {
        setCurrentShapes(prev => 
          prev.map(item => item.id === key ? { ...item, containerStyle: { ...item.containerStyle, opacity: 1 } } : item)
        );
      }, 100);

      setTimeout(() => {
        setCurrentShapes(prev => prev.map(item => 
          item.id === key ? { ...item, containerStyle: { ...item.containerStyle, opacity: 0 } } : item
        ));
        setTimeout(() => {
          setCurrentShapes(prev => prev.filter(item => item.id !== key));
        }, 2000);
      }, 10000 + Math.random() * 10000);
    };

    const interval = setInterval(() => {
      if (currentShapes.length < 2) {
        addShape();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentShapes.length]);

  return (
    <div className='component componentFloatingShapes'>
      {currentShapes.map(shape => (
        <div key={shape.id} className={`${shape.clsName}`} style={shape.containerStyle}>
          {shape.element}
        </div>
      ))}
    </div>
  );
};

export default FloatingShapes;
