import React, { useEffect } from 'react';
import useStore from '../../store';
import { Link } from 'react-router-dom';


const ManagerClientsLeadsList = ({ managerId }) => {
  const managedProjects = useStore(state => state.managedProjects);
  const fetchManagedProjects = useStore(state => state.fetchManagedProjects);

  useEffect(() => {
    if (managerId) {
      fetchManagedProjects(managerId);
    }
  }, [managerId, fetchManagedProjects]);

  if (!managedProjects.length) {
    return (
      <div className='component component-main componentManagerProjectsList card'>
        <div className='block block-trans block-ver' id='block-Projects-header'>
          <h2>No projects assigned to this manager.</h2>
          <p>You can assign this manager to a project on the corresponding page of a particular project.</p>
        </div>
      </div>
    );
  }

  return (
    <div className='component component-main componentManagerProjectsList card'>
      <div className='block block-trans block-hor' id='block-Projects-header'>
        <h2>Assigned Projects</h2>
      </div>
      <div className='card' id='card-Projects'>
        <table id='table-Projects'>
          <thead>
            <tr>
              <th data-label="Name"><p>Name</p></th>
              <th data-label="One-Time €"><p>One-Time</p></th>
              <th data-label="Monthly €"><p>Monthly</p></th>
              <th data-label="Status"><p>Status</p></th>
            </tr>
          </thead>
          <tbody>
            {managedProjects.map(project => (
              <tr key={project._id}>
                <td data-label="Name"><Link to={`/projects/${project._id}`}>{project.projectName}</Link></td>
                <td data-label="One-Time €">{project.basicPrice ? `€${project.basicPrice}` : '-'}</td>
                <td data-label="Monthly €">{project.monthPrice ? `€${project.monthPrice}` : '-'}</td>
                <td data-label="Status">{project.projectStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManagerClientsLeadsList;
